import React from 'react';
import { VideoPage } from 'src/api/types';

import { useDataContext } from 'src/context/data';
import { isMobileScreen } from 'src/utils/common';

import analytics from 'src/utils/analytics/analytics';
import isEmpty from 'lodash/isEmpty';
import { useLayoutStateContext } from 'src/context/layoutState';
import {
  type WistiaVideoPlayEvent,
  type WistiaVideoPauseEvent,
  type WistiaVideoEndEvent,
  WistiaThumbnail,
  WistiaIframePlayer,
  WistiaIframeMobilePlayer,
} from '../Video';
import PortalSlot from 'src/utils/PortalSlot';
import TextBuilder from '../TextBuilder/TextBuilder';

declare global {
  interface Window {
    _wq?: any[];
  }
}

type PageVideoProps = {
  page: VideoPage;
};

const HORIZONTAL_PADDING = 15;

const handleVideoStarted = (event: WistiaVideoPlayEvent): void => {
  analytics.trackVideoStarted({ wistiaMatcher: event.matcher, videoName: event.name, timePoint: event.time });
};

const handleVideoPaused = (event: WistiaVideoPauseEvent): void => {
  analytics.trackVideoPaused({ wistiaMatcher: event.matcher, videoName: event.name, timePoint: event.time });
};

const handleVideoEnded = (event: WistiaVideoEndEvent): void => {
  analytics.trackVideoEnded({ wistiaMatcher: event.matcher, videoName: event.name });
};

function PageVideo({ page }: PageVideoProps) {
  const {
    appState: {
      widgetData: {
        parameters: { chatbotWidth, robotBackgroundColor, robotTextColor, robotBorderColor },
      },
      currentMessageId,
    },
  } = useDataContext();

  const { toggleModalIsVisible, setModalChild, setLoading } = useLayoutStateContext();

  const isMobileDisplay = isMobileScreen();

  const widgetWidth = chatbotWidth - HORIZONTAL_PADDING * 2;

  const text = page.contentBody;

  const isFull = Boolean(page.wistiaThumbnailUrl);

  const renderMobileVideo = () => {
    return (
      <PortalSlot slotName={'dancecard-portal-slot-video-player-' + page.wistiaMatcher}>
        <WistiaIframeMobilePlayer
          matcher={page.wistiaMatcher}
          onPlay={handleVideoStarted}
          onPause={handleVideoPaused}
          onEnd={handleVideoEnded}
        />
      </PortalSlot>
    );
  };

  const renderModalVideo = () => {
    return (
      <WistiaThumbnail
        thumbnailUrl={page.wistiaThumbnailUrl}
        width={widgetWidth}
        isPlayButtonDisplayed
        onClick={() => {
          setModalChild(
            <WistiaIframePlayer
              matcher={page.wistiaMatcher}
              onPlay={handleVideoStarted}
              onPause={handleVideoPaused}
              onEnd={handleVideoEnded}
              onEmbedded={() => setLoading(false)}
            />,
          );
          toggleModalIsVisible(true);
          analytics.trackVideoOpened({ wistiaMatcher: page.wistiaMatcher });
        }}
      />
    );
  };

  return (
    <div
      id={page.slug}
      className={`rcw-page-body ${isFull ? 'rcw-page-body_full' : ''}`}
      key={'page-body-' + currentMessageId}
      style={
        isFull ? {} : { backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }
      }
    >
      {isMobileDisplay ? renderMobileVideo() : renderModalVideo()}

      {page.caption && <h3 className="rcw-page-caption rcw-page-video-caption">{page.caption}</h3>}

      <TextBuilder text={text} />

      {!isEmpty(page.footnote) && <p className="rcw-page-text-footnote">{page.footnote}</p>}
    </div>
  );
}

export default PageVideo;
