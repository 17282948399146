import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDataContext } from 'src/context/data';
import SpinnerLoader from 'src/components/SpinnerLoader/SpinnerLoader';

type LottieAnimationProps = {
  className?: string;
  data: any;
};

function LottieAnimation({ className, data }: LottieAnimationProps) {
  const {
    appState: { lottieReact, assets },
  } = useDataContext();

  if (!lottieReact || isEmpty(assets)) {
    return <SpinnerLoader isLoading={true} />;
  }

  // lottie-react exports its React component as default.
  const { default: Lottie } = lottieReact;

  return <Lottie className={className} animationData={data} />;
}

export default LottieAnimation;
