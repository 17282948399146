import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSliderContext } from '../SliderContext';

import arrowIcon from '../../../../../../assets/right-arrow.svg';

function SliderNavigation() {
  const { items, changeSlide, slidesCount, slideNumber } = useSliderContext();

  if (isEmpty(items)) {
    return null;
  }

  const renderCarouselNumber = () => {
    return (
      <>
        {slideNumber + 1} of {slidesCount}
      </>
    );
  };

  return (
    <div className="rcw-page-slider-nav">
      <div className="rcw-page-slider-nav-arrow rcw-page-slider-nav-arrow_left" onClick={() => changeSlide(-1)}>
        <img src={arrowIcon} alt="Prev slide" />
      </div>
      <div className="rcw-page-slider-nav-numbers">{renderCarouselNumber()}</div>
      <div className="rcw-page-slider-nav-arrow rcw-page-slider-nav-arrow_right" onClick={() => changeSlide(1)}>
        <img src={arrowIcon} alt="Next slide" />
      </div>
    </div>
  );
}

export default SliderNavigation;
