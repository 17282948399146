import { getDevicePixelRatio, getVisualViewportScale } from '../common';
import {
  AnalyticsProvider,
  RegisterIntegrationIdData,
  RegisterDeploymentTagData,
  RegisterMessageData,
  RegisterSplitTestData,
  TrackMessageViewedData,
  TrackPageViewedData,
  TrackSlideViewedData,
  TrackLinkOpenedData,
  TrackBackButtonPressedData,
  TrackPageButtonPressedData,
  TrackResponseButtonPressedData,
  TrackVideoOpenedData,
  TrackVideoStartedData,
  TrackVideoPausedData,
  TrackVideoEndedData,
  TrackPhoneNumberPressedData,
  RegisterTenantGroupIdData,
} from './types';
import { SplitTestGroup } from 'src/api/types';

const ANALYTICS_BUTTON_EVENT_NAMES = {
  backButton: 'back_button_pressed',
  homeButton: 'home_button_pressed',
  backToHomeButton: 'back_to_home_button_pressed',
  responseButton: 'response_button_pressed',
  pageButton: 'page_button_pressed',
  backFromPageButton: 'back_from_page_button_pressed',
};

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

class GoogleAnalytics implements AnalyticsProvider {
  private isInitialized: boolean;
  private dataToAdd: {
    debug_mode?: boolean;
    message_id?: string;
    integration_id?: string;
    deployment_tag?: string;
    split_test_group?: SplitTestGroup;
    tenant_group_id?: string;
  };
  private pageViewDuration: number;
  private timeStartedAt: number;

  constructor(private measurementId: string) {
    this.measurementId = measurementId;
    this.isInitialized = false;
    this.dataToAdd = {};
    this.pageViewDuration = 0;
    this.timeStartedAt = 0;
  }

  registerIntegrationId(data: RegisterIntegrationIdData): void {
    this.dataToAdd.integration_id = data.integrationId;
  }

  registerDeploymentTag(data: RegisterDeploymentTagData): void {
    this.dataToAdd.deployment_tag = data.deploymentTag;
  }

  registerMessageData(data: RegisterMessageData): void {
    this.dataToAdd.message_id = data.messageId;
  }

  registerSplitTestData(data: RegisterSplitTestData): void {
    this.dataToAdd.split_test_group = data.splitTestGroup;
  }

  registerTenantGroupId(data: RegisterTenantGroupIdData): void {
    this.dataToAdd.tenant_group_id = data.tenantGroupId;
  }

  init(): void {
    if (!this.measurementId || this.isInitialized) {
      return;
    }

    const isDevEnv = process.env.NODE_ENV === 'development';
    if (isDevEnv) {
      this.dataToAdd.debug_mode = isDevEnv;
    }

    this.isInitialized = true;
  }

  uninit(): void {
    this.isInitialized = false;
  }

  checkIfInitialized(): boolean {
    return this.isInitialized;
  }

  private gtag(..._args: any): void {
    const { dataLayer } = window;

    if (dataLayer) {
      dataLayer.push(arguments);
    }
  }

  private track(name: string, trackData?: any): void {
    if (!this.isInitialized) {
      return;
    }

    const data = { ...this.dataToAdd, ...trackData, send_to: this.measurementId };

    const viewportScale = getVisualViewportScale();
    if (viewportScale) {
      data.viewport_scale = viewportScale;
    }
    data.device_pixel_ratio = getDevicePixelRatio();

    this.gtag('event', name, data);
  }

  private startPageViewTimer(): void {
    this.timeStartedAt = Date.now();
  }

  private stopPageViewTimer(): void {
    if (this.timeStartedAt > 0) {
      this.pageViewDuration = (Date.now() - this.timeStartedAt) / 1000;
    }
  }

  private resetPageViewTimer(): void {
    this.timeStartedAt = 0;
    this.pageViewDuration = 0;
  }

  trackChatbotOpened(): void {
    this.track('chatbot_opened');

    this.startPageViewTimer();
  }

  trackPdcFormSkipped(): void {
    this.track('form_skipped');
  }

  trackPdcFormSubmitted(): void {
    this.track('form_submitted');
  }

  trackCallbackRequestFormSkipped(): void {
    this.track('callback_request_form_skipped');
  }

  trackCallbackRequestFormSubmitted(): void {
    this.track('callback_request_form_submitted');
  }

  trackMessageViewed(data: TrackMessageViewedData): void {
    this.track('message_viewed', { previous_message_id: data.previousMessageId, message_label: data.messageLabel });
  }

  trackPageViewed(data: TrackPageViewedData): void {
    this.track('page_viewed', {
      page_id: data.pageId,
      page_type: data.pageType,
      message_id: data.messageId,
      page_caption: data.pageCaption,
      previous_message_id: data.previousMessageId,
      asset_type: data.assetType,
    });

    this.startPageViewTimer();
  }

  trackSlideViewed(data: TrackSlideViewedData): void {
    this.track('slide_viewed', {
      slide_id: data.slideId,
      slide_image_url: data.slideImageUrl,
      slide_number: data.slideNumber,
    });
  }

  trackLinkOpened(data: TrackLinkOpenedData): void {
    this.track('link_opened', { link_title: data.linkTitle, link_url: data.linkURL });
  }

  trackBackButtonPressed(data: TrackBackButtonPressedData): void {
    this.stopPageViewTimer();

    this.track(
      ANALYTICS_BUTTON_EVENT_NAMES.backButton,
      this.pageViewDuration > 0
        ? { router_history: data.routerHistory, duration: this.pageViewDuration }
        : { router_history: data.routerHistory },
    );

    this.resetPageViewTimer();
  }

  trackHomeButtonPressed(): void {
    this.stopPageViewTimer();

    this.track(
      ANALYTICS_BUTTON_EVENT_NAMES.homeButton,
      this.pageViewDuration > 0 ? { duration: this.pageViewDuration } : {},
    );

    this.resetPageViewTimer();
  }

  trackBackToHomeButtonPressed(): void {
    this.stopPageViewTimer();

    this.track(
      ANALYTICS_BUTTON_EVENT_NAMES.backToHomeButton,
      this.pageViewDuration > 0 ? { duration: this.pageViewDuration } : {},
    );

    this.resetPageViewTimer();
  }

  trackPageButtonPressed(data: TrackPageButtonPressedData): void {
    this.stopPageViewTimer();

    this.track(
      ANALYTICS_BUTTON_EVENT_NAMES.pageButton,
      this.pageViewDuration > 0
        ? { page_caption: data.pageCaption, page_id: data.pageId, duration: this.pageViewDuration }
        : { page_caption: data.pageCaption, page_id: data.pageId },
    );

    this.resetPageViewTimer();
  }

  trackBackFromPageButtonPressed(): void {
    this.stopPageViewTimer();

    this.track(ANALYTICS_BUTTON_EVENT_NAMES.backFromPageButton, { duration: this.pageViewDuration });

    this.resetPageViewTimer();
  }

  trackResponseButtonPressed(data: TrackResponseButtonPressedData): void {
    this.stopPageViewTimer();

    this.track(
      ANALYTICS_BUTTON_EVENT_NAMES.responseButton,
      this.pageViewDuration > 0
        ? { response_label: data.responseLabel, response_value: data.responseValue, duration: this.pageViewDuration }
        : { response_label: data.responseLabel, response_value: data.responseValue },
    );

    this.resetPageViewTimer();
  }

  trackVideoOpened(data: TrackVideoOpenedData): void {
    this.track('video_opened', { wistia_matcher: data.wistiaMatcher });
  }

  trackVideoStarted(data: TrackVideoStartedData): void {
    this.track('video_started', {
      wistia_matcher: data.wistiaMatcher,
      video_name: data.videoName,
      time_point: data.timePoint,
    });
  }

  trackVideoPaused(data: TrackVideoPausedData): void {
    this.track('video_paused', {
      wistia_matcher: data.wistiaMatcher,
      video_name: data.videoName,
      time_point: data.timePoint,
    });
  }

  trackVideoEnded(data: TrackVideoEndedData): void {
    this.track('video_ended', { wistia_matcher: data.wistiaMatcher, video_name: data.videoName });
  }

  trackPhoneNumberPressed(data: TrackPhoneNumberPressedData): void {
    this.track('phone_number_pressed', { button_type: data.buttonType });
  }
}

export default GoogleAnalytics;
