import React from 'react';
import SpinnerLoader from 'src/components/SpinnerLoader/SpinnerLoader';

function SlideImage({ src, alt, isImageLoading }) {
  return (
    <div className={`rcw-page-slider-image-wrapper ${isImageLoading ? 'is-image-loading' : ''}`}>
      {isImageLoading ? <SpinnerLoader isLoading /> : <img className="rcw-page-slider-image" src={src} alt={alt} />}
    </div>
  );
}

export default SlideImage;
