import React from 'react';
import { CarouselPage } from 'src/api/types';
import { useDataContext } from 'src/context/data';
import Slider from '../Slider/Slider';

type PageCarouselProps = {
  page: CarouselPage;
};

function PageCarousel({ page }: PageCarouselProps) {
  const {
    appState: { currentMessageId },
  } = useDataContext();

  return (
    <div id={page.slug} className={`rcw-page-body rcw-page-body_full`} key={'page-body-' + currentMessageId}>
      <Slider items={page.images} />
    </div>
  );
}

export default PageCarousel;
