import { escapeRegExp, orderBy } from 'lodash';

/** Supported arithmetic operators. */
export enum ArithmeticOperator {
  Add,
  Multiply,
  Subtract,
}

/** Supported comparison operators. */
export enum ComparisonOperator {
  Equal,
  GreaterThan,
  GreaterThanOrEqual,
  LessThan,
  LessThanOrEqual,
  NotEqual,
}

/** Supported logical operators. */
export enum LogicalOperator {
  And,
  Or,
}

/** Supported built-in operands. */
export enum BuiltinOperand {
  Total,
}

/** Maps arithmetic operator symbols to their corresponding `ArithmeticOperator` enums. */
const arithmeticOperatorMap: Record<string, ArithmeticOperator> = {
  '+': ArithmeticOperator.Add,
  '*': ArithmeticOperator.Multiply,
  '-': ArithmeticOperator.Subtract,
};

/** Maps comparison operator symbols to their corresponding `ComparisonOperator` enums. */
const comparisonOperatorMap: Record<string, ComparisonOperator> = {
  '=': ComparisonOperator.Equal,
  '>': ComparisonOperator.GreaterThan,
  '>=': ComparisonOperator.GreaterThanOrEqual,
  '<': ComparisonOperator.LessThan,
  '<=': ComparisonOperator.LessThanOrEqual,
  '<>': ComparisonOperator.NotEqual,
};

/** Maps logical operator keywords to their corresponding `LogicalOperator` enums. */
const logicalOperatorMap: Record<string, LogicalOperator> = {
  and: LogicalOperator.And,
  or: LogicalOperator.Or,
};

/** Maps operand identifiers to their corresponding `BuiltinOperand` enums. */
const builtinOperandMap: Record<string, BuiltinOperand> = {
  total: BuiltinOperand.Total,
};

/** Token kinds produced by the tokenizer. */
export enum TokenKind {
  ArithmeticOperator,
  ComparisonOperator,
  LogicalOperator,
  BuiltinOperand,
  MessageSlug,
  NumericLiteral,
  LeftParenthesis,
  RightParenthesis,
}

/** Represents the base structure for tokens. */
type BaseToken<TKind extends TokenKind, TValue = undefined> = TValue extends undefined
  ? { kind: TKind }
  : { kind: TKind; value: TValue };

/** Specific token types. */
export type ArithmeticOperatorToken = BaseToken<TokenKind.ArithmeticOperator, ArithmeticOperator>;
export type ComparisonOperatorToken = BaseToken<TokenKind.ComparisonOperator, ComparisonOperator>;
export type LogicalOperatorToken = BaseToken<TokenKind.LogicalOperator, LogicalOperator>;
export type BuiltinOperandToken = BaseToken<TokenKind.BuiltinOperand, BuiltinOperand>;
export type MessageSlugToken = BaseToken<TokenKind.MessageSlug, string> & { isPrefix: boolean };
export type NumericLiteralToken = BaseToken<TokenKind.NumericLiteral, number>;
export type LeftParenthesisToken = BaseToken<TokenKind.LeftParenthesis>;
export type RightParenthesisToken = BaseToken<TokenKind.RightParenthesis>;

/** Union type representing all lexical tokens. */
export type Token =
  | ArithmeticOperatorToken
  | ComparisonOperatorToken
  | LogicalOperatorToken
  | BuiltinOperandToken
  | MessageSlugToken
  | NumericLiteralToken
  | LeftParenthesisToken
  | RightParenthesisToken;

/** Union type representing an operator token. */
export type OperatorToken = ArithmeticOperatorToken | ComparisonOperatorToken | LogicalOperatorToken;

/** Union type representing an operand token. */
export type OperandToken = BuiltinOperandToken | MessageSlugToken | NumericLiteralToken;

/** Checks if the given token is an operator. */
export function isOperatorToken(token: Token): token is OperatorToken {
  return (
    token.kind === TokenKind.ArithmeticOperator ||
    token.kind === TokenKind.ComparisonOperator ||
    token.kind === TokenKind.LogicalOperator
  );
}

/** Checks if the given token is an operand. */
export function isOperandToken(token: Token): token is OperandToken {
  return (
    token.kind === TokenKind.BuiltinOperand ||
    token.kind === TokenKind.MessageSlug ||
    token.kind === TokenKind.NumericLiteral
  );
}

/** Represents a token parser function. */
type TokenParser = (input: string, startIndex: number) => { token: Token; endIndex: number } | null;

/** Represents a token parser configuration. */
type TokenParserConfig = {
  pattern: string;
  convert: (match: RegExpExecArray) => Token;
};

/** Creates a token parser function based on a provided regex pattern and a conversion function. */
function createTokenParser({ pattern, convert }: TokenParserConfig): TokenParser {
  // Initialize the regex with the sticky flag ('y') to ensure
  // it matches starting exactly at the current index.
  const regExp = new RegExp(pattern, 'y');
  // Return the token parser function.
  return (input: string, startIndex: number) => {
    // Set the starting position for the regex search.
    regExp.lastIndex = startIndex;
    // Execute the regex against the input string.
    const match = regExp.exec(input);
    if (match !== null) {
      // Convert the match into a `Token` and return it with the updated index.
      return { token: convert(match), endIndex: regExp.lastIndex };
    }
    // No match is found.
    return null;
  };
}

/** Creates a parser that matches a specific single character. */
function createSingleCharTokenParser(char: string, createToken: () => Token): TokenParser {
  // Return the token parser function.
  return (input: string, startIndex: number) => {
    // Check if the current character matches the given character.
    if (input[startIndex] === char) {
      // Create a token and return with the index of the next character.
      return { token: createToken(), endIndex: startIndex + 1 };
    }
    // Meh, not a match.
    return null;
  };
}

/** Creates a regex alternation pattern from a mapping. */
function createAlternationPattern(mapping: Record<string, any>): string {
  const keys = Object.keys(mapping);
  // Sort keys by descending length to match longer strings first.
  const ordered = orderBy(keys, 'length', 'desc');
  // Escape each key to avoid regex syntax errors.
  const escaped = ordered.map(escapeRegExp);
  // Join escaped keys with '|' to form an alternation pattern
  // and wrap in parentheses to form a capture group.
  return '(' + escaped.join('|') + ')';
}

/** Array of token parser functions used by the tokenizer. */
const tokenParsers: TokenParser[] = [
  createTokenParser({
    pattern: createAlternationPattern(arithmeticOperatorMap),
    convert: ([_, key]) => ({ kind: TokenKind.ArithmeticOperator, value: arithmeticOperatorMap[key] }),
  }),
  createTokenParser({
    pattern: createAlternationPattern(comparisonOperatorMap),
    convert: ([_, key]) => ({ kind: TokenKind.ComparisonOperator, value: comparisonOperatorMap[key] }),
  }),
  createTokenParser({
    pattern: createAlternationPattern(logicalOperatorMap) + '\\b',
    convert: ([_, key]) => ({ kind: TokenKind.LogicalOperator, value: logicalOperatorMap[key] }),
  }),
  createTokenParser({
    pattern: '@' + createAlternationPattern(builtinOperandMap) + '\\b',
    convert: ([_, key]) => ({ kind: TokenKind.BuiltinOperand, value: builtinOperandMap[key] }),
  }),
  createTokenParser({
    pattern: '(\\${1,2})([-\\w]+)',
    convert: ([_, dollars, slug]) => ({
      kind: TokenKind.MessageSlug,
      value: slug,
      // Two dollar signs ($$) signify that the slug is a prefix, not a full message slug.
      isPrefix: dollars.length === 2,
    }),
  }),
  createTokenParser({
    // Numeric literals are parsed as positive. Handling negatives would require the tokenizer
    // to distinguish between negative numbers and subtraction, which falls outside its scope.
    pattern: '(\\d+(\\.\\d+)?)\\b',
    convert: ([_, value]) => ({ kind: TokenKind.NumericLiteral, value: Number(value) }),
  }),
  createSingleCharTokenParser('(', () => ({ kind: TokenKind.LeftParenthesis })),
  createSingleCharTokenParser(')', () => ({ kind: TokenKind.RightParenthesis })),
];

/**
 * Converts an input string into an array of tokens by parsing each segment sequentially.
 *
 * @param input - The string to be tokenized.
 * @returns An array of `Token` objects representing the parsed tokens from the input string.
 * @throws Will throw an error if an invalid token is encountered during tokenization.
 */
export function tokenize(input: string): Token[] {
  const output: Token[] = [];
  let index = 0;

  // Attempts to parse the next token.
  function parseNextToken(): boolean {
    for (const parseToken of tokenParsers) {
      const result = parseToken(input, index);
      if (result !== null) {
        // Add the parsed token to the output array.
        output.push(result.token);
        // Advance the index to the end of the parsed token.
        index = result.endIndex;
        // Indicate a successful parse.
        return true;
      }
    }
    // No matching token found.
    return false;
  }

  // Parse input tokens until exhausted.
  while (true) {
    // Skip any leading whitespace characters.
    while (input[index] === ' ') {
      ++index;
    }
    // Break the loop if the end of the input string is reached.
    if (index >= input.length) {
      break;
    }
    // Attempt to parse the next token. If unsuccessful, throw an error.
    if (!parseNextToken()) {
      throw new Error('Invalid token at index ' + index);
    }
  }
  return output;
}
