import {
  ArithmeticOperator,
  isOperandToken,
  isOperatorToken,
  LeftParenthesisToken,
  LogicalOperator,
  OperatorToken,
  TokenKind,
  type Token,
} from './tokenizer';

/**
 * Represents a function that transforms an array of tokens.
 *
 * @param tokens - The array of tokens to be transformed.
 * @returns A new array of tokens after the transformation is applied.
 */
export type Transformer = (tokens: Token[]) => Token[];

/** Applies unary negation for numeric literals following a minus sign. */
export const applyUnaryNegation: Transformer = (tokens) => {
  const output: Token[] = [];
  const size = tokens.length;
  let index = 0;
  while (index < size) {
    const token = tokens[index];
    const nextIndex = index + 1;
    // Check if the current token is a minus operator and there are more tokens after it.
    if (
      nextIndex < size &&
      token.kind === TokenKind.ArithmeticOperator &&
      token.value === ArithmeticOperator.Subtract
    ) {
      const nextToken = tokens[nextIndex];
      // Check if the next token is a numeric literal.
      if (nextToken.kind === TokenKind.NumericLiteral) {
        // Determine if the minus sign is unary.
        let isUnary = index === 0;
        if (!isUnary) {
          const prevToken = tokens[index - 1];
          // Unary if the previous token is an operator or left parenthesis.
          isUnary = isOperatorToken(prevToken) || prevToken.kind === TokenKind.LeftParenthesis;
        }
        // Negate the numeric literal if the minus is unary.
        if (isUnary) {
          output.push({ kind: TokenKind.NumericLiteral, value: -nextToken.value });
          // Skip the next token (numeric literal).
          index += 2;
          continue;
        }
      }
    }
    // If no unary negation, push the current token as is.
    output.push(token);
    ++index;
  }
  return output;
};

/** Returns the precedence of the given operator token. */
function getOperatorPrecedence(token: OperatorToken): number {
  switch (token.kind) {
    case TokenKind.ArithmeticOperator:
      switch (token.value) {
        case ArithmeticOperator.Add:
          return 3;
        case ArithmeticOperator.Subtract:
          return 3;
        case ArithmeticOperator.Multiply:
          return 4;
      }
    case TokenKind.ComparisonOperator:
      return 2;
    case TokenKind.LogicalOperator:
      switch (token.value) {
        case LogicalOperator.And:
          return 1;
        case LogicalOperator.Or:
          return 0;
      }
  }
}

/**
 * Converts an infix expression to postfix (Reverse Polish Notation).
 *
 * This function implements the Shunting Yard algorithm to convert a sequence of tokens
 * representing an infix expression into a postfix expression. It handles operators with
 * different precedence levels, supports parentheses for grouping, and processes operands
 * and operators appropriately.
 */
export const convertInfixToPostfix: Transformer = (tokens) => {
  const output: Token[] = [];
  const stack: (OperatorToken | LeftParenthesisToken)[] = [];
  for (const token of tokens) {
    if (isOperandToken(token)) {
      // Add operands to the output.
      output.push(token);
    } else if (isOperatorToken(token)) {
      // Move operators with higher or equal precedence from stack to output.
      const precedence = getOperatorPrecedence(token);
      while (stack.length > 0) {
        const top = stack[stack.length - 1];
        if (!isOperatorToken(top) || getOperatorPrecedence(top) < precedence) {
          break;
        }
        output.push(top);
        stack.pop();
      }
      // Push the current operator onto the stack.
      stack.push(token);
    } else if (token.kind === TokenKind.LeftParenthesis) {
      // Push left parenthesis onto the stack for later processing.
      stack.push(token);
    } else if (token.kind === TokenKind.RightParenthesis) {
      // Pop until a left parenthesis is encountered.
      while (true) {
        const top = stack.pop();
        if (top === undefined) {
          throw new Error('Unmatched right parenthesis');
        }
        if (top.kind === TokenKind.LeftParenthesis) {
          break;
        }
        output.push(top);
      }
    } else {
      throw new Error('Unexpected token');
    }
  }
  // Move remaining operators to the output.
  while (true) {
    const top = stack.pop();
    if (top === undefined) {
      break;
    }
    if (top.kind === TokenKind.LeftParenthesis) {
      throw new Error('Unmatched left parenthesis');
    }
    output.push(top);
  }
  return output;
};

// Define the pipeline of transformers.
const pipeline: Transformer[] = [applyUnaryNegation, convertInfixToPostfix];

/** Applies all transformers in the pipeline to the input tokens. */
export const transform: Transformer = (tokens) => pipeline.reduce((input, transformer) => transformer(input), tokens);
