import {
  KEYS,
  getDataFromSessionStorage,
  removeItemFromSessionStorage,
  setDataToSessionStorage,
} from 'src/utils/persistentStorage';

/** Maps a message slug to its current score. */
export type Scores = Record<string, number | undefined>;
/** Maps a message slug to its current message and response labels. */
export type Labels = Record<string, { messageLabel: string; responseLabel: string }>;

let cachedScores: Scores | null = null;
let cachedLabels: Labels | null = null;
let cachedTotalScore: number | null = null;

/**
 * Reads the scores from session storage, using a cached value if available.
 *
 * @returns The current scores.
 */
export function readScores(): Scores {
  if (cachedScores === null) {
    cachedScores = getDataFromSessionStorage(KEYS.customerResponseScores);
  }
  return cachedScores ?? {};
}

/**
 * Reads the labels of the scored messages and responses from session storage,
 * using a cached value if available.
 *
 * @returns The current labels of the scored messages and responses.
 */
export function readLabels(): Labels {
  if (cachedLabels === null) {
    cachedLabels = getDataFromSessionStorage(KEYS.customerResponseLabels);
  }
  return cachedLabels ?? {};
}

/**
 * Writes the scores to session storage and updates the cache.
 *
 * @param scores - The scores to store.
 */
function writeScores(scores: Scores): void {
  setDataToSessionStorage(KEYS.customerResponseScores, scores);
  cachedScores = scores;
}

/**
 * Writes labels of the scored messages and responses to session storage and updates the cache.
 *
 * @param labels - The scores to store.
 */
function writeLabels(labels: Labels): void {
  setDataToSessionStorage(KEYS.customerResponseLabels, labels);
  cachedLabels = labels;
}

/**
 * Sets the score for a specific message.
 *
 * @param slug - The message slug.
 * @param score - The score to set.
 */
export function setMessageScore(slug: string, score: number): void {
  const scores = readScores();
  scores[slug] = score;
  writeScores(scores);
  // Clear the cached total score just in case.
  cachedTotalScore = null;
}

/**
 * Sets the labels of the scored message and response for a specific message.
 *
 * @param slug - The message slug.
 * @param messageLabel - The message label to set.
 * @param responseLabel - The response label to set.
 */
export function setLabels(slug: string, messageLabel: string, responseLabel: string): void {
  const labels = readLabels();
  labels[slug] = { messageLabel, responseLabel };
  writeLabels(labels);
  // Clear the cached total score just in case.
  cachedTotalScore = null;
}

/**
 * Retrieves the score for a specific message.
 *
 * @param slug - The message slug.
 * @returns The score, or 0 if not found.
 */
export function getMessageScore(slug: string): number {
  return readScores()[slug] ?? 0;
}

/**
 * Calculates the total score from the stored message scores. If a `prefix` is provided,
 * it sums the scores of messages whose slugs start with the specified prefix.
 *
 * @param prefix - (Optional) A string used to filter message slugs. Only slugs starting
 * with this prefix will be included in the total score calculation.
 * @returns The total score from the matched message scores, or the sum of all message
 * scores if no prefix is provided.
 */
export function calculateTotalScore(prefix?: string): number {
  const scores = readScores();
  const keys = Object.keys(scores);
  return (prefix === undefined ? keys : keys.filter((slug) => slug.startsWith(prefix)))
    .map((slug) => scores[slug] ?? 0)
    .reduce((total, score) => total + score, 0);
}

/**
 * Retrieves the cached total score or calculates it if it's not cached.
 *
 * @returns The total score calculated from all message scores.
 */
export function getTotalScore(): number {
  if (cachedTotalScore !== null) {
    return cachedTotalScore;
  }
  const totalScore = calculateTotalScore();
  cachedTotalScore = totalScore;
  return totalScore;
}

/** Removes all message scores from the session storage. */
export function clearAllMessageScores(): void {
  removeItemFromSessionStorage(KEYS.customerResponseScores);
  removeItemFromSessionStorage(KEYS.customerResponseLabels);
  cachedScores = null;
  cachedLabels = null;
  cachedTotalScore = null;
}
