import React, { useEffect } from 'react';
import { SlideDataProps } from './types';
import { useSliderContext } from './SliderContext';
import SlidesList from './SlideList/SlideList';

function Slider({ items }: SlideDataProps) {
  const { initSlider, clearSliderData, handleTouchStart, handleTouchMove } = useSliderContext();

  useEffect(() => {
    initSlider(items);

    return () => clearSliderData();
  }, []);

  return (
    <div className="rcw-page-slider-wrapper" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <div className="rcw-page-slider">
        <SlidesList />
      </div>
    </div>
  );
}

export default Slider;
