import React from 'react';

import Slide from './Slide/Slide';
import { useSliderContext } from '../SliderContext';

export default function SlidesList() {
  const { slideNumber, items } = useSliderContext();

  return (
    <div className="rcw-page-slide-list" style={{ transform: `translateX(${-slideNumber * 100}%)` }}>
      {items.map(slide => (
        <Slide key={slide.id} data={slide} />
      ))}
    </div>
  );
}
