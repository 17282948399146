import { CustomerResponseScoringRules } from 'src/api/types';
import { Bridge, compile, type CompiledRule } from './compiler';
import {
  calculateTotalScore,
  getMessageScore,
  getTotalScore,
  setMessageScore,
  setLabels,
  type Scores,
} from './storage';

// Reexport the necessary functions from the storage module.
export { readScores, readLabels, clearAllMessageScores, type Scores, type Labels } from './storage';

/** Maps a message slug to its compiled rule. */
type CompiledRules = Partial<{ [slug: string]: CompiledRule }>;

/** Maps a message slug to its source rule string. */
type SourceRules = Partial<{ [slug: string]: string }>;

/** Provides access to score data used in rule evaluation, including specific message scores and total scores. */
const bridge: Bridge = {
  getTotalScore,
  getMessageScore: (slug, isPrefix) => (isPrefix ? calculateTotalScore(slug) : getMessageScore(slug)),
};

/**
 * Compiles a set of source rules into corresponding `CompiledRules`.
 *
 * @param source - An object containing the source rules to compile.
 * @returns A collection of compiled rules.
 * @throws An error indicating the specific slug if a rule cannot be parsed successfully.
 */
function compileRules(source: SourceRules): CompiledRules {
  return Object.entries(source).reduce((rules, [slug, line]) => {
    try {
      if (line !== undefined) {
        rules[slug] = compile(line, bridge);
      }
    } catch (error) {
      // Rethrow the error with the slug for better debugging.
      if (error instanceof Error) {
        error.message = `Rule for slug '${slug}': ${error.message}`;
      }
      throw error;
    }
    return rules;
  }, {});
}

/**
 * Evaluates a rule based on the provided source string.
 *
 * This function compiles the source string and immediately executes
 * it using the default `bridge` for external data access.
 *
 * @param source - The source string representing the rule to evaluate.
 * @returns `true` or `false` based on the result of the evaluated rule.
 *
 * @throws Will throw an error if the rule cannot be compiled or evaluated.
 */
export const evaluateRule = (source: string): boolean => compile(source, bridge)();

/**
 * Interface for scoring and display strategies that handle user interactions
 * and determine the visibility of response buttons and message pages.
 */
export interface Scoring {
  /**
   * Processes a user response by updating the relevant score.
   *
   * @param label - The label of the user response to handle.
   */
  handleResponseClick(label: string): void;

  /**
   * Determines if a response button should be displayed.
   *
   * @param slug - The slug identifying the response button.
   * @returns `true` if the button should be shown, otherwise `false`.
   */
  shouldShowResponseButton(slug: string): boolean;

  /**
   * Determines if a message page should be displayed.
   *
   * @param slug - The slug identifying the message page.
   * @returns `true` if the page should be shown, otherwise `false`.
   */
  shouldShowMessagePage(slug: string): boolean;
}

/**
 * Simple no-scoring implementation that always returns `true`
 * for showing response buttons and message pages.
 */
class NoScoring implements Scoring {
  handleResponseClick(_label: string): void {}

  shouldShowResponseButton(_slug: string): boolean {
    return true;
  }

  shouldShowMessagePage(_slug: string): boolean {
    return true;
  }
}

/**
 * Implements the `Scoring` interface to manage scoring logic
 * for message-based interactions.
 *
 * The `MessageScoring` class handles the processing of user responses
 * by updating message scores, and it determines the visibility of response buttons
 * and message pages based on rules.
 */
class MessageScoring implements Scoring {
  readonly #messageSlug: string;
  readonly #messageLabel: string;
  readonly #buttonScoreByLabel: Scores;
  readonly #buttonRules: CompiledRules;
  readonly #pageRules: CompiledRules;

  constructor(
    messageSlug: string,
    messageLabel: string,
    buttonScoreByLabel: Scores,
    buttonRules: CompiledRules,
    pageRules: CompiledRules,
  ) {
    this.#messageSlug = messageSlug;
    this.#messageLabel = messageLabel;
    this.#buttonScoreByLabel = buttonScoreByLabel;
    this.#buttonRules = buttonRules;
    this.#pageRules = pageRules;
  }

  handleResponseClick(label: string): void {
    const score = this.#buttonScoreByLabel[label];
    if (score !== undefined) {
      setMessageScore(this.#messageSlug, score);
      setLabels(this.#messageSlug, this.#messageLabel, label);
    }
  }

  shouldShowResponseButton(slug: string): boolean {
    const rule = this.#buttonRules[slug];
    return rule === undefined || rule();
  }

  shouldShowMessagePage(slug: string): boolean {
    const rule = this.#pageRules[slug];
    return rule === undefined || rule();
  }
}

/**
 * Factory function that creates a `Scoring` instance based on the provided
 * message slug and scoring rules.
 *
 * Depending on the existence of specific rules for the message, this function
 * returns either a `MessageScoring` instance with customized scoring logic
 * or a default `NoScoring` instance if no rules are found.
 *
 * @param messageSlug - The slug of the message to base the scoring on.
 * @param rules - The collection of scoring rules and configurations.
 * @returns A `Scoring` instance tailored to the provided message and rules.
 */
export function createScoring(messageSlug: string, messageLabel: string, rules: CustomerResponseScoringRules): Scoring {
  const { messages, buttonTables } = rules;
  const message = messages?.[messageSlug];
  if (message !== undefined) {
    const { buttonTableName, buttonRules, pageRules } = message;
    const tableNotFound = Symbol();
    const buttonTable = buttonTableName !== undefined ? (buttonTables?.[buttonTableName] ?? tableNotFound) : undefined;
    if (buttonTable === tableNotFound) {
      throw new Error(`Button table '${buttonTableName}' not found`);
    }
    return new MessageScoring(
      messageSlug,
      messageLabel,
      buttonTable?.labels ?? {},
      buttonRules !== undefined ? compileRules(buttonRules) : {},
      pageRules !== undefined ? compileRules(pageRules) : {},
    );
  }
  return new NoScoring();
}
