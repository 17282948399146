import React, { useCallback } from 'react';

import { useDataContext } from 'src/context/data';
import { isMarkdown } from 'src/utils/common';

import SVG from 'react-inlinesvg';
import LottieAnimation from 'src/components/LottieAnimation/LottieAnimation';
import WistiaThumbnail from '../Video/WistiaThumbnail';

const PAGE_BUTTON_THUMBNAIL_AVATAR_WIDTH = 120 * 2;

function PageButton({ page, onClick }) {
  const {
    appState: {
      assets,
      widgetData: {
        parameters: { robotBackgroundColor, robotTextColor, robotBorderColor },
      },
    },
  } = useDataContext();

  if (!page) {
    return null;
  }

  const renderPageButtonContent = useCallback(() => {
    const assetContent = page.asset && assets[page.asset.id];
    switch (page.pageType) {
      case 'article':
        const mediaType = isMarkdown(page.mediaType) ? page.mediaType.replace('markdown:', '').trim() : page.mediaType;
        return (
          <div
            id={'rcw-page-button-' + page.id}
            className="rcw-message-page-button"
            onClick={onClick}
            style={{ backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }}
          >
            {page.asset && page.asset.type === 'animation-lottie' && (
              <div className="rcw-message-page-button-avatar">
                <LottieAnimation className="rcw-page-button-lottie-animation" data={assetContent} />
              </div>
            )}

            {page.asset && page.asset.type === 'image-svg' && (
              <div className="rcw-message-page-button-avatar">
                <SVG className="rcw-page-button-image-svg" src={assetContent} />
              </div>
            )}

            <div className="rcw-message-page-button-text">
              <span className="rcw-message-page-button-capture">{page.caption}</span>
              <span className="rcw-message-page-button-media-type">{mediaType}</span>
            </div>
          </div>
        );

      case 'video':
        return (
          <div
            id={'rcw-page-button-' + page.id}
            className="rcw-message-page-button"
            style={{ backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }}
            onClick={onClick}
          >
            <div
              className="rcw-message-page-button-avatar rcw-message-page-button-avatar-video"
              style={{ maxWidth: `${PAGE_BUTTON_THUMBNAIL_AVATAR_WIDTH / 2}px` }}
            >
              <WistiaThumbnail thumbnailUrl={page.wistiaThumbnailUrl} width={PAGE_BUTTON_THUMBNAIL_AVATAR_WIDTH} />
            </div>
            <div className="rcw-message-page-button-text">
              <span className="rcw-message-page-button-capture">{page.caption}</span>
              <span className="rcw-message-page-button-media-type">{page.mediaType}</span>
            </div>
          </div>
        );

      case 'form':
        return (
          <div
            id={'rcw-page-button-' + page.id}
            className="rcw-message-page-button"
            onClick={onClick}
            style={{ backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }}
          >
            {page.asset && page.asset.type === 'animation-lottie' && (
              <div className="rcw-message-page-button-avatar">
                <LottieAnimation className="rcw-page-button-lottie-animation" data={assetContent} />
              </div>
            )}

            {page.asset && page.asset.type === 'image-svg' && (
              <div className="rcw-message-page-button-avatar">
                <SVG className="rcw-page-button-image-svg" src={assetContent} />
              </div>
            )}

            <div className="rcw-message-page-button-text">
              <span className="rcw-message-page-button-capture">{page.caption}</span>
              <span className="rcw-message-page-button-media-type">{page.mediaType}</span>
            </div>
          </div>
        );

      case 'callback-request':
        return (
          <div
            id={'rcw-page-button-' + page.id}
            className="rcw-message-page-button"
            onClick={onClick}
            style={{ backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }}
          >
            {page.asset && page.asset.type === 'animation-lottie' && (
              <div className="rcw-message-page-button-avatar">
                <LottieAnimation className="rcw-page-button-lottie-animation" data={assetContent} />
              </div>
            )}

            {page.asset && page.asset.type === 'image-svg' && (
              <div className="rcw-message-page-button-avatar">
                <SVG className="rcw-page-button-image-svg" src={assetContent} />
              </div>
            )}

            <div className="rcw-message-page-button-text">
              <span className="rcw-message-page-button-capture">{page.caption}</span>
              <span className="rcw-message-page-button-media-type">{page.mediaType}</span>
            </div>
          </div>
        );

      case 'carousel':
        const imgUrl = page.thumbnailUrl ? page.thumbnailUrl : page.images[0].url;
        return (
          <div
            id={'rcw-page-button-' + page.id}
            className="rcw-message-page-button"
            onClick={onClick}
            style={{ backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }}
          >
            <div className="rcw-message-page-button-avatar">
              <img src={imgUrl} alt="Carousel avatar" />
            </div>
            <div className="rcw-message-page-button-text">
              <span className="rcw-message-page-button-capture">{page.caption}</span>
              <span className="rcw-message-page-button-media-type">{page.mediaType}</span>
            </div>
          </div>
        );

      default:
        return null;
    }
  }, [page, assets]);

  return renderPageButtonContent();
}

export default PageButton;
