import mixpanelLib, { Mixpanel } from 'mixpanel-browser';
import { getDevicePixelRatio, getVisualViewportScale } from '../common';
import {
  AnalyticsProvider,
  RegisterIntegrationIdData,
  RegisterDeploymentTagData,
  RegisterMessageData,
  TrackMessageViewedData,
  TrackPageViewedData,
  TrackSlideViewedData,
  TrackLinkOpenedData,
  TrackBackButtonPressedData,
  TrackPageButtonPressedData,
  TrackResponseButtonPressedData,
  TrackPhoneNumberPressedData,
  TrackVideoOpenedData,
  TrackVideoStartedData,
  TrackVideoPausedData,
  TrackVideoEndedData,
  RegisterSplitTestData,
  RegisterTenantGroupIdData,
} from './types';
import { SplitTestGroup } from 'src/api/types';

const ANALYTICS_BUTTON_EVENT_NAMES = {
  backButton: 'Back button pressed',
  homeButton: 'Home button pressed',
  backToHomeButton: 'Back to home button pressed',
  responseButton: 'Response button pressed',
  pageButton: 'Page button pressed',
  backFromPageButton: 'Back from page button pressed',
};

class MixpanelAnalytics implements AnalyticsProvider {
  private mixpanel: Mixpanel | null;
  private dataToAdd: {
    'Message ID'?: string;
    'Integration ID'?: string;
    'Deployment Tag'?: string;
    'Split Test Group'?: SplitTestGroup;
    'Tenant Group ID'?: string;
  };

  constructor(private token: string) {
    this.token = token;
    this.mixpanel = null;
    this.dataToAdd = {};
  }

  registerIntegrationId(data: RegisterIntegrationIdData): void {
    this.dataToAdd['Integration ID'] = data.integrationId;
  }

  registerDeploymentTag(data: RegisterDeploymentTagData): void {
    this.dataToAdd['Deployment Tag'] = data.deploymentTag;
  }

  registerMessageData(data: RegisterMessageData): void {
    this.dataToAdd['Message ID'] = data.messageId;
  }

  registerSplitTestData(data: RegisterSplitTestData): void {
    this.dataToAdd['Split Test Group'] = data.splitTestGroup;
  }

  registerTenantGroupId(data: RegisterTenantGroupIdData): void {
    this.dataToAdd['Tenant Group ID'] = data.tenantGroupId;
  }

  init(): void {
    const isDevENV = process.env.NODE_ENV === 'development';

    if (!this.token) {
      return;
    }

    this.mixpanel = mixpanelLib.init(this.token, { debug: isDevENV }, 'leadnurturing_dancecard');
  }

  uninit(): void {
    if (this.mixpanel) {
      // @ts-ignore
      this.mixpanel.persistence.clear();
      this.mixpanel = null;
    }
  }

  checkIfInitialized(): boolean {
    return this.mixpanel !== null;
  }

  private track(name: string, trackData?: any): void {
    if (this.mixpanel === null) {
      return;
    }

    const data = { ...this.dataToAdd, ...trackData };

    const viewportScale = getVisualViewportScale();
    if (viewportScale) {
      data['Viewport Scale'] = viewportScale;
    }
    data['Device pixel ratio'] = getDevicePixelRatio();

    this.mixpanel.track(name, data);
  }

  private setPageViewTimerStart(): void {
    if (this.mixpanel === null) {
      return;
    }

    this.mixpanel.time_event(ANALYTICS_BUTTON_EVENT_NAMES.backButton);
    this.mixpanel.time_event(ANALYTICS_BUTTON_EVENT_NAMES.homeButton);
    this.mixpanel.time_event(ANALYTICS_BUTTON_EVENT_NAMES.backToHomeButton);
    this.mixpanel.time_event(ANALYTICS_BUTTON_EVENT_NAMES.responseButton);
    this.mixpanel.time_event(ANALYTICS_BUTTON_EVENT_NAMES.pageButton);
    this.mixpanel.time_event(ANALYTICS_BUTTON_EVENT_NAMES.backFromPageButton);
  }

  trackChatbotOpened(): void {
    this.track('Chatbot opened');

    this.setPageViewTimerStart();
  }

  trackPdcFormSkipped(): void {
    this.track('Form skipped');
  }

  trackPdcFormSubmitted(): void {
    this.track('Form submitted');
  }

  trackCallbackRequestFormSkipped(): void {
    this.track('Callback request form skipped');
  }

  trackCallbackRequestFormSubmitted(): void {
    this.track('Callback request form submitted');
  }

  trackMessageViewed(data: TrackMessageViewedData): void {
    this.track('Message viewed', { 'Previous Message ID': data.previousMessageId, 'Message Label': data.messageLabel });
  }

  trackPageViewed(data: TrackPageViewedData): void {
    this.track('Page viewed', {
      'Page ID': data.pageId,
      'Page Type': data.pageType,
      'Message ID': data.messageId,
      'Page Caption': data.pageCaption,
      'Previous Message ID': data.previousMessageId,
      'Asset Type': data.assetType,
    });

    this.setPageViewTimerStart();
  }

  trackSlideViewed(data: TrackSlideViewedData): void {
    this.track('Slide viewed', {
      'Slide ID': data.slideId,
      'Slide Image URL': data.slideImageUrl,
      'Slide Number': data.slideNumber,
    });
  }

  trackLinkOpened(data: TrackLinkOpenedData): void {
    this.track('Link opened', { 'Link Title': data.linkTitle, 'Link URL': data.linkURL });
  }

  trackBackButtonPressed(data: TrackBackButtonPressedData): void {
    this.track(ANALYTICS_BUTTON_EVENT_NAMES.backButton, { 'Router History': data.routerHistory });
  }

  trackHomeButtonPressed(): void {
    this.track(ANALYTICS_BUTTON_EVENT_NAMES.homeButton);
  }

  trackBackToHomeButtonPressed(): void {
    this.track(ANALYTICS_BUTTON_EVENT_NAMES.backToHomeButton);
  }

  trackPageButtonPressed(data: TrackPageButtonPressedData): void {
    this.track(ANALYTICS_BUTTON_EVENT_NAMES.pageButton, { 'Page Caption': data.pageCaption, 'Page ID': data.pageId });
  }

  trackBackFromPageButtonPressed(): void {
    this.track(ANALYTICS_BUTTON_EVENT_NAMES.backFromPageButton);
  }

  trackResponseButtonPressed(data: TrackResponseButtonPressedData): void {
    this.track(ANALYTICS_BUTTON_EVENT_NAMES.responseButton, {
      'Response Label': data.responseLabel,
      'Response Value': data.responseValue,
    });
  }

  trackVideoOpened(data: TrackVideoOpenedData): void {
    this.track('Video opened', { 'Wistia Matcher': data.wistiaMatcher });
  }

  trackVideoStarted(data: TrackVideoStartedData): void {
    this.track('Video started', {
      'Wistia Matcher': data.wistiaMatcher,
      'Video name': data.videoName,
      'Time Point': data.timePoint,
    });
  }

  trackVideoPaused(data: TrackVideoPausedData): void {
    this.track('Video paused', {
      'Wistia Matcher': data.wistiaMatcher,
      'Video name': data.videoName,
      'Time Point': data.timePoint,
    });
  }

  trackVideoEnded(data: TrackVideoEndedData): void {
    this.track('Video ended', { 'Wistia Matcher': data.wistiaMatcher, 'Video name': data.videoName });
  }

  trackPhoneNumberPressed(data: TrackPhoneNumberPressedData): void {
    this.track('Phone number pressed', { 'Button Type': data.buttonType });
  }
}

export default MixpanelAnalytics;
