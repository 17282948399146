export enum Steps {
  init,
  thanks,
}

export type OwnProps = {
  onDone: () => void;
};

export type Gender = 'male' | 'female';

export interface FieldsProps {
  firstName: string;
  lastName: string;
  email: string;
  age: number;
  gender: Gender;
  postalCode: number;
}
