import React, { useEffect, useState, memo, CSSProperties } from 'react';

import { WidgetParameters, DialogConfig } from 'src/api/types';

import { constructSamples } from './utils';

import * as colors from 'src/utils/styles/colors.module.scss';

/* - - - - - - - - - - - - - - - - - - - - - */

const INTERVAL_FACTOR = 12;

type TestimonialsProps = {
  maxCollapsedWidth?: number;
  collapsed?: boolean;
  parameters: WidgetParameters | undefined;
  dialogConfig: DialogConfig | undefined;
};

function Testimonials(props: TestimonialsProps) {
  const { maxCollapsedWidth, collapsed, parameters, dialogConfig } = props;

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const samples = constructSamples(dialogConfig?.credibilityBuilders, dialogConfig?.testimonials);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = currentIndex === samples.length - 1 ? 0 : currentIndex + 1;

      setCurrentIndex(nextIndex);
    }, INTERVAL_FACTOR * 1000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  if (samples.length === 0) return <div />;

  return (
    <div
      className={`rcw-testimonial-container ${collapsed ? 'rcw-testimonial-container-collapsed' : ''}`}
      style={{ '--testimonials-max-collapsed-width': maxCollapsedWidth + 'px' } as CSSProperties}
      data-cy="testimonial-container-collapsed"
    >
      <div className="rcw-testimonials-list">
        <li
          className={`testimonials-list-item ${collapsed ? 'testimonials-list-item-collapsed' : ''}`}
          style={{ color: parameters && collapsed ? parameters.collapsedButtonTextColor : colors.text }}
        >
          {samples[currentIndex]}
        </li>
      </div>
    </div>
  );
}

function areEqual(p: TestimonialsProps, n: TestimonialsProps) {
  return p.collapsed == n.collapsed;
}

export default memo(Testimonials, areEqual);
