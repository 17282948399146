import React, { useCallback, useRef } from 'react';

import { useDataContext } from 'src/context/data';
import analytics from 'src/utils/analytics/analytics';

import { formatPhoneNumber, isMobileScreen, isSinglePage } from 'src/utils/common';
import useResizeObserver from 'src/utils/hooks/useResizeObserver';

import {
  renderCloseIcon,
  renderPhoneIcon,
  renderArrowIcon,
  renderHomeIcon,
  renderCrossInCircleIcon,
} from 'src/utils/iconRenderers';
import { clearAllMessageScores } from 'src/utils/scoring';

import * as colors from 'src/utils/styles/colors.module.scss';

function Header() {
  const {
    appState: {
      currentMessage,
      currentPage,
      routerHistory,
      widgetData: { parameters, config },
    },
    setWidgetOpenedStatus,
    goBack,
    goHome,
    changeHeight,
  } = useDataContext();

  const headerRef = useRef<HTMLDivElement>(null);
  useResizeObserver(
    headerRef,
    useCallback((entry) => {
      changeHeight('headerHeight', entry.contentRect.height);
    }, []),
  );

  const handleBackBtnClick = () => {
    analytics.trackBackButtonPressed({ routerHistory });
    goBack();
  };

  const renderBackButton = () => {
    if ((!isSinglePage(currentMessage.pages) && currentPage) || routerHistory.length > 1) {
      return (
        <button data-cy="go-back-nav-control" onClick={handleBackBtnClick}>
          {renderArrowIcon(parameters.titleFontColor)}
        </button>
      );
    }
    return null;
  };

  const renderHomeButton = () => {
    if (routerHistory.length > 1) {
      return (
        <button data-cy="back-to-home-nav-control" onClick={handleHomeBtnClick}>
          {renderHomeIcon(parameters.titleFontColor)}
        </button>
      );
    }
    return null;
  };

  const handleHomeBtnClick = () => {
    clearAllMessageScores();
    analytics.trackHomeButtonPressed();
    goHome();
  };

  const renderCloseButton = () => {
    if (!isMobileScreen() && parameters.isCompactLauncherEnabled) {
      return (
        <button
          className={`rcw-header-close-button rounded ${parameters.isPhoneNumberEnabled ? 'header-close-button_tall' : ''}`}
          onClick={() => setWidgetOpenedStatus(false)}
        >
          {renderCrossInCircleIcon(colors.white)}
        </button>
      );
    }

    return (
      <button
        className={`rcw-header-close-button mobile ${parameters.isPhoneNumberEnabled ? 'header-close-button_tall' : ''}`}
        onClick={() => setWidgetOpenedStatus(false)}
      >
        {renderCrossInCircleIcon(colors.white)}
      </button>
    );
  };

  const renderPhoneNumber = () => {
    if (!parameters.isPhoneNumberEnabled) {
      return null;
    }
    return (
      <div className="rcw-header-phone-number" data-cy="header-phone-number">
        {!isMobileScreen() && !parameters.isCompactLauncherEnabled && (
          <a
            className="rcw-header-phone-icon-holder"
            style={{ borderColor: parameters.phoneIconColor }}
            href={`tel:${config.phoneNumberParameters.phoneNumber}`}
            onClick={() => analytics.trackPhoneNumberPressed({ buttonType: 'icon' })}
            data-cy="header-phone-icon"
          >
            {renderPhoneIcon(parameters.phoneIconColor)}
          </a>
        )}

        <a
          className="rcw-header-phone-number-link"
          style={{ color: parameters.titleFontColor }}
          onClick={() => analytics.trackPhoneNumberPressed({ buttonType: 'number' })}
          href={`tel:${config.phoneNumberParameters.phoneNumber}`}
        >
          {formatPhoneNumber(config.phoneNumberParameters.phoneNumber as string)}
        </a>
      </div>
    );
  };

  return (
    <div ref={headerRef}>
      <div
        className={`rcw-header ${parameters.isPhoneNumberEnabled ? 'header_tall' : ''}`}
        style={{ backgroundColor: parameters.titleBackgroundColor }}
        data-cy="rcw-header"
      >
        <div
          className={`header-nav-controls ${parameters.isPhoneNumberEnabled ? 'header-nav-controls_tall' : ''}`}
          data-cy="header-nav-controls"
        >
          {renderBackButton()}
          {renderHomeButton()}
        </div>

        <h4 className="rcw-title" style={{ color: parameters.titleFontColor }} data-cy="header-title">
          {parameters.title}
        </h4>

        {renderPhoneNumber()}

        {renderCloseButton()}
      </div>
    </div>
  );
}

export default Header;
