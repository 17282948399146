import { useState, useEffect, useCallback } from 'react';
import { getViewportSize } from '../common';

interface ScreenDimensions {
  windowWidth: number;
  windowHeight: number;
  diagonalCoefficient: number;
}

export const useScreenDimensions = (): ScreenDimensions => {
  const [dimensions, setDimensions] = useState<ScreenDimensions>({
    windowWidth: 0,
    windowHeight: 0,
    diagonalCoefficient: 1,
  });

  const calculateDimensions = useCallback(() => {
    const viewportSize = getViewportSize();

    const width = viewportSize.windowWidth;
    const height = viewportSize.windowHeight;
    const screenDiagonal = Math.round(Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)));
    const diagonalCoefficient = screenDiagonal * 0.001;

    requestAnimationFrame(() =>
      setDimensions({
        windowWidth: width,
        windowHeight: height,
        diagonalCoefficient,
      }),
    );
  }, []);

  useEffect(() => {
    calculateDimensions();

    window.addEventListener('resize', calculateDimensions);

    return () => {
      window.removeEventListener('resize', calculateDimensions);
    };
  }, []);

  return dimensions;
};
