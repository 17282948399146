import {
  AnalyticsProvider,
  RegisterIntegrationIdData,
  RegisterDeploymentTagData,
  RegisterMessageData,
  RegisterSplitTestData,
  RegisterTenantGroupIdData,
  TrackMessageViewedData,
  TrackPageViewedData,
  TrackSlideViewedData,
  TrackLinkOpenedData,
  TrackBackButtonPressedData,
  TrackPageButtonPressedData,
  TrackResponseButtonPressedData,
  TrackVideoOpenedData,
  TrackVideoStartedData,
  TrackVideoPausedData,
  TrackVideoEndedData,
  TrackPhoneNumberPressedData,
} from './types';

class AnalyticsAggregator implements AnalyticsProvider {
  private providers: AnalyticsProvider[];

  constructor() {
    this.providers = [];
  }

  addAnalyticsProvider(provider: AnalyticsProvider): void {
    this.providers.push(provider);
  }

  init(): void {
    this.providers.forEach((provider) => provider.init());
  }

  uninit(): void {
    this.providers.forEach((provider) => provider.uninit());
  }

  checkIfInitialized(): boolean {
    return this.providers.every((provider) => provider.checkIfInitialized());
  }

  registerIntegrationId(data: RegisterIntegrationIdData): void {
    this.providers.forEach((provider) => provider.registerIntegrationId(data));
  }

  registerDeploymentTag(data: RegisterDeploymentTagData): void {
    this.providers.forEach((provider) => provider.registerDeploymentTag(data));
  }

  registerMessageData(data: RegisterMessageData): void {
    this.providers.forEach((provider) => provider.registerMessageData(data));
  }

  registerSplitTestData(data: RegisterSplitTestData): void {
    this.providers.forEach((provider) => provider.registerSplitTestData(data));
  }

  registerTenantGroupId(data: RegisterTenantGroupIdData): void {
    this.providers.forEach((provider) => provider.registerTenantGroupId(data));
  }

  trackChatbotOpened(): void {
    this.providers.forEach((provider) => provider.trackChatbotOpened());
  }

  trackPdcFormSkipped(): void {
    this.providers.forEach((provider) => provider.trackPdcFormSkipped());
  }

  trackPdcFormSubmitted(): void {
    this.providers.forEach((provider) => provider.trackPdcFormSubmitted());
  }

  trackCallbackRequestFormSkipped(): void {
    this.providers.forEach((provider) => provider.trackCallbackRequestFormSkipped());
  }

  trackCallbackRequestFormSubmitted(): void {
    this.providers.forEach((provider) => provider.trackCallbackRequestFormSubmitted());
  }

  trackMessageViewed(data: TrackMessageViewedData): void {
    this.providers.forEach((provider) => provider.trackMessageViewed(data));
  }

  trackPageViewed(data: TrackPageViewedData): void {
    this.providers.forEach((provider) => provider.trackPageViewed(data));
  }

  trackSlideViewed(data: TrackSlideViewedData): void {
    this.providers.forEach((provider) => provider.trackSlideViewed(data));
  }

  trackLinkOpened(data: TrackLinkOpenedData): void {
    this.providers.forEach((provider) => provider.trackLinkOpened(data));
  }

  trackBackButtonPressed(data: TrackBackButtonPressedData): void {
    this.providers.forEach((provider) => provider.trackBackButtonPressed(data));
  }

  trackHomeButtonPressed(): void {
    this.providers.forEach((provider) => provider.trackHomeButtonPressed());
  }

  trackBackToHomeButtonPressed(): void {
    this.providers.forEach((provider) => provider.trackBackToHomeButtonPressed());
  }

  trackPageButtonPressed(data: TrackPageButtonPressedData): void {
    this.providers.forEach((provider) => provider.trackPageButtonPressed(data));
  }

  trackBackFromPageButtonPressed(): void {
    this.providers.forEach((provider) => provider.trackBackFromPageButtonPressed());
  }

  trackResponseButtonPressed(data: TrackResponseButtonPressedData): void {
    this.providers.forEach((provider) => provider.trackResponseButtonPressed(data));
  }

  trackVideoOpened(data: TrackVideoOpenedData): void {
    this.providers.forEach((provider) => provider.trackVideoOpened(data));
  }

  trackVideoStarted(data: TrackVideoStartedData): void {
    this.providers.forEach((provider) => provider.trackVideoStarted(data));
  }

  trackVideoPaused(data: TrackVideoPausedData): void {
    this.providers.forEach((provider) => provider.trackVideoPaused(data));
  }

  trackVideoEnded(data: TrackVideoEndedData): void {
    this.providers.forEach((provider) => provider.trackVideoEnded(data));
  }

  trackPhoneNumberPressed(data: TrackPhoneNumberPressedData): void {
    this.providers.forEach((provider) => provider.trackPhoneNumberPressed(data));
  }
}

export default AnalyticsAggregator;
