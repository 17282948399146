import { getHost } from './host';

export function shouldUseShadowDom(): boolean {
  const host = getHost();

  const isShadowDomSupported = Boolean(HTMLElement.prototype.attachShadow);
  const isShadowDomInUse = Boolean(host && host.getAttribute('data-use-shadow-dom') !== 'no');

  return isShadowDomSupported && isShadowDomInUse;
}
