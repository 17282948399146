import React, { useRef } from 'react';

import { useDataContext } from 'src/context/data';

import { useEventListenerOnce } from 'src/utils/hooks';

import Header from './Header/Header';
import Testimonials from './Testimonials/Testimonials';
import StyledModal from './StyledModal/StyledModal';
import Content from './Content/Content';
import { isMobileScreen } from 'src/utils/common';

/* - - - - - - - - - - - - - - - - - - - - - */

function Conversation() {
  const {
    appState: {
      widgetData: { config, parameters },
    },
    registerFirstClick,
  } = useDataContext();

  const containerRef = useRef<HTMLDivElement>(null);

  useEventListenerOnce('click', registerFirstClick, containerRef, true);

  return (
    <div
      className={`rcw-conversation-container ${!isMobileScreen() && parameters.isCompactLauncherEnabled ? 'height-full' : 'height-calculated'}`}
      ref={containerRef}
      data-cy="conversation-container"
    >
      <Header />
      {parameters.isTestimonialsAndCredibilityBuildersBarEnabled && (
        <Testimonials dialogConfig={config} parameters={parameters} />
      )}
      <Content />
      <StyledModal />
    </div>
  );
}

export default Conversation;
