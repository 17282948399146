import React, { useEffect, useState } from 'react';
import { WistiaVideoEndEvent, WistiaVideoPauseEvent, WistiaVideoPlayEvent } from './types';

type WistiaIframePlayerProps = {
  matcher: string;
  onPlay: (event: WistiaVideoPlayEvent) => void;
  onPause: (event: WistiaVideoPauseEvent) => void;
  onEnd: (event: WistiaVideoEndEvent) => void;
  onEmbedded: () => void;
  isMobile?: boolean;
};

const MARGIN_X2 = 120;

function WistiaIframePlayer({ matcher, onPlay, onPause, onEnd, isMobile, onEmbedded }: WistiaIframePlayerProps) {
  if (!matcher) {
    return null;
  }

  const [iframeWrapperOpacity, setIframeWrapperOpacity] = useState(0);

  const embedWistiaConfig = {
    id: 'dancecard-' + matcher,
    hashedId: matcher,
    onEmbedded: function (video) {
      if (onEmbedded) {
        onEmbedded();
      }
      // In order to set the dimensions for the video modal,
      // it is needed to get the aspect ratio of the video from wistiaAPI.
      // Then the width, which is crucial for modal video dimensions,
      // is set directly in the iframe using wistiaAPI method.
      if (!isMobile) {
        const videoAspectRatio = video.aspect();
        let width: number;
        if (window.innerWidth / window.innerHeight <= videoAspectRatio) {
          width = window.innerWidth - MARGIN_X2;
        } else {
          width = window.innerHeight * videoAspectRatio - MARGIN_X2;
        }
        video.width(width).height(width / (videoAspectRatio || 1));
        setTimeout(() => {
          setIframeWrapperOpacity(1);
        }, 100);
      }
    },
    onReady: function (video) {
      video.bind('play', () => onPlay({ matcher, name: video.name(), time: video.time() }));
      video.bind('pause', () => onPause({ matcher, name: video.name(), time: video.time() }));
      video.bind('end', () => onEnd({ matcher, name: video.name() }));
      video.bind('beforeremove', () => {
        return () => video.unbind();
      });
    },
  };

  useEffect(() => {
    const { _wq } = window;
    if (_wq) {
      _wq.push(embedWistiaConfig);
    }

    return () => {
      if (_wq) {
        _wq.push({ revoke: embedWistiaConfig });
      }
    };
  }, [matcher]);

  return (
    <div style={{ opacity: iframeWrapperOpacity, transitionProperty: 'opacity', transitionDuration: '500ms' }}>
      <iframe
        id={'dancecard-' + matcher}
        allow="fullscreen"
        title="Message video embedded"
        src={`//fast.wistia.net/embed/iframe/${matcher}?playsinline=false&fullscreenButton=true`}
        frameBorder={0}
        className="wistia_embed"
        name="wistia_embed"
        data-cy="wistia-video-iframe"
      />
    </div>
  );
}

export default WistiaIframePlayer;
