function insertStylesIntoTarget(element, options) {
  if (options.global) {
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(element);
    return;
  }
  var widget = window.DancecardMicrosite;
  var slot = widget && widget.styleSlot;
  var parent = slot || document.head;
  parent.appendChild(element);
}

export default insertStylesIntoTarget;
