declare global {
  interface Window {
    DANCECARD_LEAD_NURTURING_CHATBOT_INTEGRATION_ID?: string;
  }
}

const devIntegrationId = '0e30b953-38dc-4acb-9906-e836788a2bf8';

const regexScriptUrl =
  /\.dancecardrx\.com\/([a-z0-9]{8}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{12})\/main.js/;

export function getWidgetScriptSrc(): string | null {
  const widgetScript = <HTMLScriptElement>window.document.getElementById('dancecard-leadnurturing-chatbot-widget');

  return widgetScript && widgetScript.src;
}

export function getIntegrationIdFromWidgetScriptSrc(src: string): string | null {
  const widgetSrcArray = regexScriptUrl.exec(src);
  return widgetSrcArray && widgetSrcArray[1];
}

export function getBaseUrlFromWidgetScriptSrc(url: string): string | null {
  const indexOfFilename = url.lastIndexOf('main.js');

  if (indexOfFilename === -1) {
    return null;
  }

  return url.substring(0, indexOfFilename);
}

const widgetScriptSrc = getWidgetScriptSrc();

if (widgetScriptSrc) {
  const baseWidgetUrl = getBaseUrlFromWidgetScriptSrc(widgetScriptSrc);
  if (baseWidgetUrl) {
    __webpack_public_path__ = baseWidgetUrl;
  }
}

const prodIntegrationId =
  window.DANCECARD_LEAD_NURTURING_CHATBOT_INTEGRATION_ID ||
  (widgetScriptSrc && getIntegrationIdFromWidgetScriptSrc(widgetScriptSrc));

const integrationId = prodIntegrationId || devIntegrationId;

export default integrationId;
