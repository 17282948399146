import { AnyPage } from 'src/api/types';
import * as dimensions from 'src/utils/styles/dimensions.module.scss';

/* - - - - - - - - - - - - - - - - - - - - - */

export function formatPhoneNumber(phoneNumberString: string) {
  if (!phoneNumberString || typeof phoneNumberString !== 'string') return '';

  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) return ['(', match[2], ') ', match[3], '-', match[4]].join('');

  return '';
}

/* - - - - - - - - - - - - - - - - - - - - - */

export const getViewportSize = () => {
  // Some mobile browsers (iOS Safari) need special handling
  const vh = window.visualViewport ? window.visualViewport.height : window.innerHeight;
  const vw = window.visualViewport ? window.visualViewport.width : window.innerWidth;
  return { windowWidth: vw, windowHeight: vh };
};

/* - - - - - - - - - - - - - - - - - - - - - */

export function getVisualViewportScale(): number | undefined {
  return window.visualViewport?.scale;
}

/* - - - - - - - - - - - - - - - - - - - - - */

export function getDevicePixelRatio(): number {
  return window.devicePixelRatio;
}

/* - - - - - - - - - - - - - - - - - - - - - */

export const isMobileScreen = () => {
  return window.matchMedia(`(max-width: ${dimensions.mobileBreakpoint}px)`).matches;
};

/* - - - - - - - - - - - - - - - - - - - - - */

export const isSinglePage = (pages?: AnyPage[]) => {
  if (!pages) {
    return false;
  }
  return pages.length === 1;
};

/* - - - - - - - - - - - - - - - - - - - - - */

export const isMarkdown = (str: string): boolean => {
  return /^markdown:/.test(str);
};

/* - - - - - - - - - - - - - - - - - - - - - */

export const blockBodyScroll = () => {
  if (typeof document === 'undefined') return;

  const { body } = document;

  if (document && body && body.style) {
    // Class is defined in src/utils/global.scss
    document.body.classList.add('rcw-block-body-scroll');
  }
};

export const allowBodyScroll = () => {
  if (typeof document === 'undefined') return;

  const { body } = document;

  if (document && body && body.style) {
    document.body.classList.remove('rcw-block-body-scroll');
  }
};

/* - - - - - - - - - - - - - - - - - - - - - */

interface TextBlock {
  type: 'text';
  text: string;
}

interface LinkBlock {
  type: 'link';
  text: string;
  url: string;
  options: string;
}

export type Block = TextBlock | LinkBlock;

export function parseTextToExtractMarkdownLinks(line: string): Block[] {
  const result: Block[] = [];
  const regex = /\[(.+?)\]\((.*?)\)(\{(.*?)\})?/g;
  const length = line.length;
  let i = 0;
  while (i < length) {
    const match = regex.exec(line);
    const j = match !== null ? match.index : length;
    // Push the text block first if such exists.
    if (i < j) {
      result.push({
        type: 'text',
        text: line.slice(i, j),
      });
    }
    // No match? Means the text block was all that is left.
    if (match === null) {
      break;
    }
    // Push the matched link block.
    result.push({
      type: 'link',
      text: match[1],
      url: match[2],
      options: match[4],
    });
    // Next iteration starts with the end of the last link.
    i = match.index + match[0].length;
  }
  return result;
}
