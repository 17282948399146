import React, { useState } from 'react';
import InputMask from 'react-input-mask';

import { sendCallbackRequest } from 'src/api';
import { getInitialQueryParams, setCallbackRequestFormsSubmitted } from 'src/utils/persistentStorage';
import { useDataContext } from 'src/context/data';
import useForm from 'src/utils/hooks/useForm';
import analytics from 'src/utils/analytics/analytics';
import { transformMessageScores } from './utils';

import * as colors from 'src/utils/styles/colors.module.scss';

import Input, { InputElementProps } from './Input/Input';
import { CallbackRequest } from 'src/api/types';

/* - - - - - - - - - - - - - - - - - - - - - */

type OwnProps = {
  onDone: (value?: CallbackRequest) => void;
  pageSlug: string;
};

interface FieldsProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  preferredCallTime: string;
}

/* - - - - - - - - - - - - - - - - - - - - - */

function CallbackRequestForm({ onDone, pageSlug }: OwnProps) {
  const {
    appState: {
      widgetData: {
        parameters: { titleBackgroundColor, titleFontColor },
        config: {
          callbackRequestParameters: { isEmailFieldEnabled },
        },
      },
    },
  } = useDataContext();

  const [isRequestErrorVisible, setIsRequestErrorVisible] = useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);

  const handleCallbackRequestFormSubmitted = (value: CallbackRequest) => {
    setCallbackRequestFormsSubmitted(pageSlug, value);
    analytics.trackCallbackRequestFormSubmitted();
    onDone(value);
  };
  const handleCallbackRequestFormSkipped = () => {
    analytics.trackCallbackRequestFormSkipped();
    onDone();
  };

  const {
    handleSubmit,
    handleChange,
    data: user,
    errors,
  } = useForm<FieldsProps>({
    // TODO: Make validation rules a separate constant
    validations: {
      firstName: {
        required: {
          value: true,
          message: 'First name is required.',
        },
      },
      lastName: {
        required: {
          value: true,
          message: 'Last name is required.',
        },
      },
      email: isEmailFieldEnabled
        ? {
            pattern: {
              value: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$',
              message: 'Email is required',
            },
            required: {
              value: true,
              message: 'Email is required.',
            },
          }
        : undefined,
      phoneNumber: {
        pattern: {
          value: '[0-9]',
          message: 'Phone number is required.',
        },
        required: {
          value: true,
          message: 'Phone number is required.',
        },
      },
      preferredCallTime: {
        required: {
          value: true,
          message: 'Preferred call time is required.',
        },
      },
    },
    onSubmit: () => {
      let requestedData: CallbackRequest = {
        source: 'microsite',
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: '+1' + user.phoneNumber.replace(/[^\d]/g, ''), // Add american (canadian) +1 code and unmask string before send phone number.
        preferredCallTime: user.preferredCallTime,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        initialStateSlug: 'submitted',
        initialQueryParams: getInitialQueryParams(),
        scores: transformMessageScores(),
      };
      if (isEmailFieldEnabled) {
        requestedData.email = user.email;
      }

      setIsSending(true);
      sendCallbackRequest({
        body: requestedData,
        onSuccess: () => {
          if (isRequestErrorVisible) {
            setIsRequestErrorVisible(false);
          }
          setIsSending(false);
          handleCallbackRequestFormSubmitted(requestedData);
        },
        onError: () => {
          setIsRequestErrorVisible(true);
          setIsSending(false);
        },
      });
    },
  });

  const renderForm = () => {
    return (
      <form className="rcw-form" onSubmit={handleSubmit}>
        <Input
          id="First-name-callback-request-field"
          type="text"
          placeholder="First name"
          value={user.firstName || ''}
          onChange={handleChange('firstName')}
          data-cy="callback-request-input-first-name"
          color={titleBackgroundColor}
          isInvalid={!!errors.firstName}
        />

        <Input
          id="Last-name-callback-request-field"
          type="text"
          placeholder="Last name"
          value={user.lastName || ''}
          onChange={handleChange('lastName')}
          data-cy="callback-request-input-last-name"
          color={titleBackgroundColor}
          isInvalid={!!errors.lastName}
        />

        {isEmailFieldEnabled && (
          <Input
            type="text"
            placeholder="Email address"
            inputMode="email"
            value={user.email || ''}
            onChange={handleChange('email')}
            data-cy="callback-request-input-email"
            color={titleBackgroundColor}
            isInvalid={!!errors.email}
          />
        )}

        <InputMask mask="(999) 999-9999" value={user.phoneNumber} onChange={handleChange<number>('phoneNumber')}>
          {(inputProps: InputElementProps) => (
            <Input
              {...inputProps}
              id="Phone-number-callback-request-field"
              type="tel"
              placeholder="Phone number"
              inputMode="numeric"
              data-cy="callback-request-input-phone-number"
              color={titleBackgroundColor}
              isInvalid={!!errors.phoneNumber}
            />
          )}
        </InputMask>

        <select
          id="Preferred-call-time-callback-request-field"
          className="callback-request-form-select"
          style={{
            outlineColor: Boolean(errors.preferredCallTime) ? colors.error : titleBackgroundColor,
            borderColor: Boolean(errors.preferredCallTime) ? colors.error : titleBackgroundColor,
            color: user.preferredCallTime ? colors.text : '#9098a9',
          }}
          onChange={handleChange('preferredCallTime')}
          defaultValue="Preferred call time"
          data-cy="callback-request-input-preferred-call-time"
        >
          <option disabled>Preferred call time</option>
          <option value="8am-10am">8am - 10am</option>
          <option value="10am-12pm">10am - 12pm</option>
          <option value="12pm-2pm">12pm - 2pm</option>
          <option value="2pm-4pm">2pm - 4pm</option>
        </select>

        {isRequestErrorVisible && (
          <span className="rcw-form-request-error-message">Form submission error, repeat or skip</span>
        )}
        <div className="rcw-form-buttons-container">
          <button
            type="button"
            disabled={isSending}
            onClick={() => handleCallbackRequestFormSkipped()}
            data-cy="callback-request-skip-btn"
          >
            Skip
          </button>
          <button
            type="submit"
            className="rcw-capture-personal-data-submit-button"
            style={{ backgroundColor: titleBackgroundColor, color: titleFontColor }}
            disabled={isSending}
            data-cy="callback-request-submit-btn"
          >
            {isSending ? 'Sending...' : 'Send'}
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className="rcw-capture-personal-data-container" data-cy="capture-personal-data-container">
      {renderForm()}
    </div>
  );
}

export default CallbackRequestForm;
