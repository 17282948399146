import React from 'react';

import { DataContextProvider } from 'src/context/data';
import { LayoutStateContextProvider } from 'src/context/layoutState';
import { BotStyleCustomization } from './Widget/BotStyleCustomization';
import Widget from './Widget/Widget';

/* - - - - - - - - - - - - - - - - - - - - - */

function ConnectedWidget() {
  return (
    <DataContextProvider>
      <BotStyleCustomization />
      <LayoutStateContextProvider>
        <Widget />
      </LayoutStateContextProvider>
    </DataContextProvider>
  );
}

export default ConnectedWidget;
