import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';

import App from './App';
import { evaluateRule } from './utils/scoring';
import { getTargetElements } from './utils/targetElements';
import { shouldUseShadowDom } from './utils/shadowDom';
import widgetStyles from './widget-styles.scss';
import globalStyles from 'src/utils/styles/global.scss';
import integrationId from './utils/integrationId';

window.DancecardMicrosite = {};

// TODO: Need to clean up the whole module a bit.
if (process.env.NODE_ENV === 'development') {
  window.DancecardMicrosite.evaluateRule = evaluateRule;
}

const { styleSlot, renderIn } = getTargetElements();
const isShadowDom = shouldUseShadowDom();

function renderApp(element: ReactNode) {
  if (renderIn !== null) {
    const root = ReactDOM.createRoot(renderIn);
    root.render(element);
  }
}

// Allows inject global styles with style-loader in webpack.
if (styleSlot) {
  window.DancecardMicrosite.styleSlot = styleSlot;
  widgetStyles.use();
  globalStyles.use({ global: true });
}

// @ts-ignore
const developmentHelpers = window.DANCECARD_LEAD_NURTURING_CHATBOT_DEVELOPMENT_HELPERS;
if (developmentHelpers !== undefined) {
  const ref = process.env.REACT_APP_GIT_REF;
  const sha = process.env.REACT_APP_GIT_COMMIT_SHA;
  if (ref && sha) {
    developmentHelpers.setGitRefAndSha(ref, sha);
  }
}

if (process.env.REACT_APP_SENTRY_DSN) {
  const options: Sentry.BrowserOptions = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    environment: process.env.NODE_ENV,
  };
  if (process.env.NODE_ENV === 'development') {
    options.allowUrls = ['localhost', /https:\/\/chatbot-widget.dancecard.dev/];
  } else if (process.env.NODE_ENV === 'production') {
    options.allowUrls = [
      /https:\/\/leadnurturing-stage-chatbot-widget.dancecardrx.com/,
      /https:\/\/leadnurturing-chatbot-widget.dancecardrx.com/,
    ];
  }
  if (process.env.REACT_APP_DEPLOYMENT_TAG) {
    options.release = process.env.REACT_APP_DEPLOYMENT_TAG;
  }
  Sentry.init(options);
  Sentry.withScope((scope) => {
    if (integrationId) {
      scope.setTag('integration-id', integrationId);
    }
  });
}

if (isShadowDom) {
  renderApp(<App />);

  if (module.hot) {
    module.hot.accept(['./App'], () => {
      const NextApp = require('./App').default;
      renderApp(<NextApp />);
    });
  }
} else {
  renderApp(<App />);

  if (module.hot) {
    module.hot.accept(['./App'], () => {
      const NextApp = require('./App').default;
      renderApp(<NextApp />);
    });
  }
}
