import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { ArticlePage } from 'src/api/types';
import { useDataContext } from 'src/context/data';
import { isMarkdown } from 'src/utils/common';

import SVG from 'react-inlinesvg';
import LottieAnimation from 'src/components/LottieAnimation/LottieAnimation';
import TextBuilder from '../TextBuilder/TextBuilder';

type PageArticleProps = {
  page: ArticlePage;
};

function PageArticle({ page }: PageArticleProps) {
  const {
    appState: {
      assets,
      widgetData: {
        parameters: { robotBackgroundColor, robotTextColor, robotBorderColor },
      },
      currentMessageId,
    },
  } = useDataContext();

  const assetContent = page.asset && assets[page.asset.id];

  const text = page.contentBody;

  const isFull = Boolean(page.asset || text?.length >= 100);

  const footnoteText = page.footnote;

  return (
    <div
      id={page.slug}
      className={`rcw-page-body ${isFull ? 'rcw-page-body_full' : ''}`}
      key={'page-body-' + currentMessageId}
      style={
        isFull ? {} : { backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }
      }
    >
      {page.asset && page.asset.type === 'animation-lottie' && (
        <div className="rcw-lottie-container">
          <LottieAnimation className="rcw-lottie-animation" data={assetContent} />
        </div>
      )}

      {page.asset && page.asset.type === 'image-svg' && (
        <div className="rcw-svg-container">
          <SVG className="rcw-image-svg" src={assetContent} />
        </div>
      )}

      <TextBuilder text={text} isMarkdown={isMarkdown(page.mediaType)} />

      {!isEmpty(footnoteText) && <p className="rcw-page-text-footnote">{footnoteText}</p>}
    </div>
  );
}

export default PageArticle;
