import React, { useRef, useCallback, useMemo } from 'react';

import { Response } from 'src/api/types';
import { useDataContext } from 'src/context/data';
import isEmpty from 'lodash/isEmpty';

import analytics from 'src/utils/analytics/analytics';

import { createResponseGroups } from './utils';

import arrowNextIcon from '../../../../assets/right-arrow.svg';
import { renderHomeIcon, renderShortArrowIcon } from 'src/utils/iconRenderers';
import { clearAllMessageScores, Scoring } from 'src/utils/scoring';
import useResizeObserver from 'src/utils/hooks/useResizeObserver';
import * as colors from 'src/utils/styles/colors.module.scss';

type ResponsesProps = { scoring: Scoring };

function Responses({ scoring }: ResponsesProps) {
  const { appState, goForward, setCurrentResponseGroupInfo, goHome, setResponsesGroupHidden, changeHeight } =
    useDataContext();
  const {
    routerHistory,
    widgetData: { config, parameters },
    currentMessageId,
    isResponsesGroupHide,
    currentResponseGroupInfo: { index },
  } = appState;

  const responsesContainerRef = useRef<HTMLDivElement>(null);
  useResizeObserver(
    responsesContainerRef,
    useCallback((entry) => {
      changeHeight('responsesHeight', Math.ceil(entry.contentRect.height));
    }, []),
  );

  const responseGroups = useMemo(() => {
    const responses = config.script[currentMessageId].quickResponses;
    return createResponseGroups(
      // Filter out responses that should not be shown based on scoring rules
      // before grouping them.
      responses.filter((response) => {
        const { slug } = config.script[response.value];
        return scoring.shouldShowResponseButton(slug);
      }),
      parameters.customerResponsePageSize,
    );
  }, [currentMessageId]);

  const renderResponseItem = (response: Response, i: number) => {
    const handleResponseClick = () => {
      scoring.handleResponseClick(response.label);
      analytics.trackResponseButtonPressed({ responseLabel: response.label, responseValue: response.value });
      goForward(response.value);
    };

    return (
      <div
        key={response.id}
        id={'responseId' + response.id}
        className={`rcw-response-button rcw-response-button-for-${config.script[response.value].slug}`}
        style={{
          borderColor: parameters.isUserBorderEnabled ? parameters.titleBackgroundColor : 'transparent',
          background: parameters.chatOptionButtonBackgroundColor,
          boxShadow: parameters.isUserShadowEnabled ? colors.boxShadow : 'none',
          fontSize: parameters.fontSize + 'px',
          color: parameters.chatOptionButtonTextColor,
        }}
        onClick={handleResponseClick}
        data-cy="response-button"
      >
        <span>{response.label}</span>
        {renderShortArrowIcon(parameters.chatOptionButtonTextColor)}
      </div>
    );
  };

  const renderPrevNavButton = index > 0;
  const renderNextNavButton = index < responseGroups.length - 1;

  const renderResponseGroups = (groups: Array<Array<Response>>) => {
    return groups.map((group, i) => (
      <div key={i} className="rcw-response-group" data-cy="responses-active-group">
        {i === index && group.map(renderResponseItem)}
      </div>
    ));
  };

  const renderBackToHomeButton = () => {
    if (routerHistory.length === 1) {
      return null;
    }

    const handleBackToHomeClick = () => {
      analytics.trackBackToHomeButtonPressed();
      clearAllMessageScores();
      goHome();
    };

    return (
      <div className="rcw-back-to-home-button-holder" data-cy="back-to-home-button">
        <button className="rcw-nav-back-to-home-button" onClick={handleBackToHomeClick}>
          {renderHomeIcon()}
          <span>Back to Home</span>
        </button>
      </div>
    );
  };

  const renderResponses = () => {
    if (isResponsesGroupHide) {
      const handleBackFromPageClick = () => {
        setResponsesGroupHidden(false);
        analytics.trackBackFromPageButtonPressed();
      };
      return (
        <div className="rcw-responses-container" data-cy="responses-container">
          <div className="rcw-responses-wrapper">
            <div
              className="rcw-response-button rcw-response-back-button"
              style={{
                borderColor: parameters.isUserBorderEnabled ? parameters.titleBackgroundColor : 'transparent',
                background: parameters.chatOptionButtonBackgroundColor,
                boxShadow: parameters.isUserShadowEnabled ? colors.boxShadow : 'none',
                fontSize: parameters.fontSize + 'px',
                color: parameters.chatOptionButtonTextColor,
              }}
              data-cy="response-back-button"
              onClick={handleBackFromPageClick}
            >
              Back
            </div>
          </div>
        </div>
      );
    }

    if (isEmpty(responseGroups)) {
      return renderBackToHomeButton();
    }

    return (
      <div className="rcw-responses-wrapper">
        {renderPrevNavButton && (
          <button
            className="rcw-nav-responses-group-button prev"
            onClick={() => setCurrentResponseGroupInfo(index - 1)}
            data-cy="prev-responses-button"
          >
            <img src={arrowNextIcon} alt="Prev responses" />
          </button>
        )}
        {renderResponseGroups(responseGroups)}
        {renderNextNavButton && (
          <button
            className="rcw-nav-responses-group-button next"
            onClick={() => setCurrentResponseGroupInfo(index + 1)}
            data-cy="next-responses-button"
          >
            <img src={arrowNextIcon} alt="Next responses" />
          </button>
        )}
      </div>
    );
  };

  return (
    <div ref={responsesContainerRef} className="rcw-responses-container" data-cy="responses-container">
      {renderResponses()}
    </div>
  );
}

export default Responses;
