import React from 'react';
import Modal from 'react-modal';
import { useLayoutStateContext } from 'src/context/layoutState';
import { useDataContext } from 'src/context/data';
import crossIcon from '../../../../assets/cross-icon.svg';
import { modalStyles, contentWrapper, closeButton, crossIconStyles } from './style';

function StyledModal() {
  const {
    appState: { currentPage },
  } = useDataContext();

  const { wistiaVideoModalIsVisible, toggleModalIsVisible, child, isLoading } = useLayoutStateContext();

  const wistiaMatcher = currentPage && 'wistiaMatcher' in currentPage ? currentPage.wistiaMatcher : null;

  return (
    <Modal
      key={wistiaMatcher}
      isOpen={wistiaVideoModalIsVisible}
      style={modalStyles}
      shouldCloseOnOverlayClick
      onRequestClose={() => toggleModalIsVisible(false)}
      contentLabel="Modal"
    >
      <div style={contentWrapper}>
        {!isLoading && (
          <div style={closeButton} onClick={() => toggleModalIsVisible(false)}>
            <img style={crossIconStyles} src={crossIcon} alt="Close" />
          </div>
        )}
        {child}
      </div>
    </Modal>
  );
}

export default StyledModal;
