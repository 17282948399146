import integrationId from './integrationId';
import { Scores, Labels } from './scoring';
import { CallbackRequest, SplitTestGroup, SplitTestMode } from 'src/api/types';

export enum KEYS {
  widgetIsOpen = 'isOpen',
  isResponsesGroupHide = 'isResponsesGroupHide',
  isPDCFormSubmitted = 'isPDCFormSubmitted',
  submittedCallbackRequestForms = 'submittedCallbackRequestForms',
  routerHistory = 'routerHistory',
  isChatbotOpeningSignalSent = 'isChatbotOpeningSignalSent',
  splitTestMode = 'splitTestMode',
  splitTestGroup = 'splitTestGroup',
  customerResponseScores = 'customerResponseScores',
  customerResponseLabels = 'customerResponseLabels',
  initialQueryParams = 'initialQueryParams',
}

const suffix = '-dancecard-leadnurturing-';

function addSuffix(key: KEYS) {
  return key + suffix + integrationId;
}

type FallbackStorage = {
  isOpen: boolean | null;
  isResponsesGroupHide: boolean;
  submittedCallbackRequestForms: Record<string, CallbackRequest>;
  currentMessage: string | null;
  isPDCFormSubmitted: boolean;
  routerHistory: (string | undefined)[];
  isChatbotOpeningSignalSent: boolean | null;
  splitTestMode: SplitTestMode;
  splitTestGroup: SplitTestGroup;
  customerResponseScores: Scores | null;
  customerResponseLabels: Labels | null;
  initialQueryParams: string;
};

let fallbackStorage: FallbackStorage = {
  isOpen: null,
  isResponsesGroupHide: false,
  submittedCallbackRequestForms: {},
  currentMessage: null,
  isPDCFormSubmitted: false,
  routerHistory: [],
  isChatbotOpeningSignalSent: null,
  splitTestMode: 'no-split-test',
  splitTestGroup: 'none',
  customerResponseScores: null,
  customerResponseLabels: null,
  initialQueryParams: '',
};

export function setDataToSessionStorage<K extends KEYS, S extends FallbackStorage>(key: K, data: S[K]): void {
  try {
    const dataToSave = JSON.stringify(data);
    sessionStorage.setItem(addSuffix(key), dataToSave);
  } catch (err) {
    fallbackStorage[key] = data;
  }
}

export function getDataFromSessionStorage(key: KEYS) {
  try {
    const data = sessionStorage.getItem(addSuffix(key));
    if (!data) {
      return null;
    }
    return JSON.parse(data);
  } catch (err) {
    return fallbackStorage[key];
  }
}

export function isDataExistInSessionStorage(key: KEYS): boolean {
  return sessionStorage.getItem(addSuffix(key)) !== null;
}

export function removeItemFromSessionStorage<K extends KEYS, S extends FallbackStorage>(key: K) {
  try {
    sessionStorage.removeItem(addSuffix(key));
  } catch (err) {
    fallbackStorage[key] = null as S[K];
  }
}

// --------------------------------------

export function setCallbackRequestFormsSubmitted(pageSlug: string, callbackRequest: CallbackRequest) {
  const storage: FallbackStorage['submittedCallbackRequestForms'] =
    getDataFromSessionStorage(KEYS.submittedCallbackRequestForms) ?? {};
  storage[pageSlug] = callbackRequest;
  return setDataToSessionStorage(KEYS.submittedCallbackRequestForms, storage);
}
export function getSubmittedCallbackRequestFormData(pageSlug: string): CallbackRequest {
  const data = getDataFromSessionStorage(KEYS.submittedCallbackRequestForms) ?? {};
  return data[pageSlug];
}
export function isCallbackRequestFormSubmitted(pageSlug: string): boolean {
  const storage: FallbackStorage['submittedCallbackRequestForms'] =
    getDataFromSessionStorage(KEYS.submittedCallbackRequestForms) ?? [];
  return storage[pageSlug] !== undefined;
}

// --------------------------------------

export function setInitialQueryParams(queryParams: string) {
  const trimmedQueryParams = queryParams.substring(1); // Remove question mark from initial query parameter.
  return setDataToSessionStorage(KEYS.initialQueryParams, trimmedQueryParams);
}
export function getInitialQueryParams(): string {
  return getDataFromSessionStorage(KEYS.initialQueryParams) ?? '';
}
export function isInitialQueryParamsExist(): boolean {
  return isDataExistInSessionStorage(KEYS.initialQueryParams);
}
