import { type CallbackRequestMessageScore } from 'src/api/types';
import { readScores, readLabels } from 'src/utils/scoring';

/**
 * Reads all message scores for the provided script and prepares them for sending to the server.
 *
 * @param script - The script object containing the messages.
 * @returns An array of `CallbackRequestMessageScore` objects to send to the server.
 */
export function transformMessageScores(): CallbackRequestMessageScore[] {
  const labelsBySlug = readLabels();
  // Read all scores and map them to an array of `CallbackRequestMessageScore` objects.
  return Object.entries(readScores()).map(([slug, maybeScore]) => ({
    messageSlug: slug,
    score: maybeScore ?? 0,
    // This field is the main reason why all the transformations are applied.
    messageLabel: labelsBySlug[slug] && labelsBySlug[slug].messageLabel,
    responseLabel: labelsBySlug[slug] && labelsBySlug[slug].responseLabel,
  }));
}
