import React from 'react';
import Message from '../Message/Message';
import { SliderContextProvider } from '../Message/Slider/SliderContext';

function Content() {
  return (
    <div key="rcw-content" className="rcw-content">
      <SliderContextProvider>
        <Message />
      </SliderContextProvider>
    </div>
  );
}

export default Content;
