import React, { CSSProperties, ReactNode, useEffect } from 'react';

import { useDataContext } from 'src/context/data';

import Conversation from './Conversation/Conversation';
import Launcher from './Launcher/Launcher';
import CompactLauncher from './CompactLauncher/CompactLauncher';

import { useScreenDimensions } from 'src/utils/hooks/useScreenDimensions';
import useZIndex, { MAX_Z_INDEX } from 'src/utils/hooks/useZIndex';
import { isMobileScreen } from 'src/utils/common';

/* - - - - - - - - - - - - - - - - - - - - - - - */

const DEFAULT_WIDGET_WIDTH = 380;

/* - - - - - - - - - - - - - - - - - - - - - - - */

function WidgetContainer({ children }: { children: ReactNode }) {
  const {
    appState: {
      widgetData,
      widgetIsOpen,
      dimensions: { widgetHeight },
    },
  } = useDataContext();

  const { windowHeight, diagonalCoefficient } = useScreenDimensions();

  const zIndexParameter = useZIndex();

  const widgetWidth = widgetData.parameters.chatbotWidth ? widgetData.parameters.chatbotWidth : DEFAULT_WIDGET_WIDTH;

  if (!isMobileScreen() && widgetData.parameters.isCompactLauncherEnabled) {
    return (
      <div
        className={`rcw-widget-container height-full ${widgetIsOpen ? 'opened' : 'collapsed'}`}
        style={
          {
            '--controlled-widget-height': windowHeight + 'px',
            '--mobile-controlled-widget-height': windowHeight + 'px',
            '--widget-z-index': MAX_Z_INDEX,
            '--diagonal-coefficient': diagonalCoefficient,
          } as CSSProperties
        }
      >
        {widgetIsOpen && <div className="rcw-overlay" />}
        {children}
      </div>
    );
  }

  return (
    <div
      className={`rcw-widget-container height-calculated ${widgetIsOpen ? 'opened' : 'collapsed'}`}
      style={
        {
          '--controlled-widget-height': widgetHeight + 'px',
          '--controlled-widget-width': widgetWidth + 'px',
          '--mobile-controlled-widget-height': windowHeight + 'px',
          '--widget-z-index': zIndexParameter,
          '--diagonal-coefficient': diagonalCoefficient,
        } as CSSProperties
      }
    >
      {children}
    </div>
  );
}

/* - - - - - - - - - - - - - - - - - - - - - - - */

function Widget() {
  const {
    makeWidgetInit,
    appState: {
      widgetData,
      currentMessageId,
      widgetIsOpen,
      widgetData: {
        parameters: { isCompactLauncherEnabled },
      },
    },
  } = useDataContext();

  useEffect(() => {
    makeWidgetInit();
  }, []);

  if (!widgetData || !currentMessageId) return <div />;

  if (isCompactLauncherEnabled) {
    return (
      <>
        <WidgetContainer>
          <Conversation />
        </WidgetContainer>
        <CompactLauncher />
      </>
    );
  }

  return (
    <WidgetContainer>
      {widgetIsOpen && <Conversation />}
      <Launcher />
    </WidgetContainer>
  );
}

export default Widget;
