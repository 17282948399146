import React, { forwardRef, CSSProperties } from 'react';
import omit from 'lodash/omit';

/* - - - - - - - - - - - - - - - - - - - - - */

export type InputElementProps = React.ComponentPropsWithoutRef<'input'>;

type Props = {
  label?: string;
  isInvalid?: boolean;
  color?: string;
};

const Input = forwardRef(({ label, isInvalid, color, ...props }: Props & InputElementProps, ref: React.Ref<any>) => {
  const propsToPass = omit(props, 'label');

  if (props.type === 'radio') {
    return (
      <div>
        <input id={props.id} ref={ref} {...propsToPass} />
        <label htmlFor={props.id}>{label}</label>
      </div>
    );
  }

  return (
    <label key={props.id} className={`input-label ${isInvalid ? 'invalid' : ''}`} htmlFor={props.id}>
      {label}
      <input
        id={props.id}
        ref={ref}
        className={`input ${isInvalid ? 'invalid' : ''}`}
        style={{ '--input-color': color } as CSSProperties}
        {...propsToPass}
      />
    </label>
  );
});

export default Input;
