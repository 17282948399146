import React, { useLayoutEffect, useRef, useCallback, useMemo, useEffect, CSSProperties } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useDataContext } from 'src/context/data';
import analytics from 'src/utils/analytics/analytics';

import { isSinglePage } from 'src/utils/common';
import { createScoring } from 'src/utils/scoring';
import useResizeObserver from 'src/utils/hooks/useResizeObserver';

import Page from './Page/Page';
import PageButton from './Page/PageButton';
import Responses from 'src/Widget/Conversation/Responses/Responses';
import SliderNavigation from '../Message/Slider/SliderNavigation/SliderNavigation';

/* - - - - - - - - - - - - - - - - - - - - - - */

function Message() {
  const {
    appState: {
      widgetData: {
        parameters: { customerResponseScoringRules, isShowSiblingPageButtonsEnabled },
      },
      currentMessageId,
      currentMessage,
      currentPage,
    },
    setResponsesGroupHidden,
    selectPage,
    changeHeight,
  } = useDataContext();

  const messageContentRef = useRef<HTMLDivElement>(null);
  const messageScrollableContainerRef = useRef<HTMLDivElement>(null);
  const actionsContainerRef = useRef<HTMLDivElement>(null);

  useResizeObserver(
    messageContentRef,
    useCallback((entry) => {
      changeHeight('messageHeight', Math.ceil(entry.contentRect.height));
    }, []),
  );

  useResizeObserver(
    actionsContainerRef,
    useCallback((entry) => {
      changeHeight('actionsHeight', Math.ceil(entry.contentRect.height));
    }, []),
  );

  useLayoutEffect(() => {
    // Scrolls each new message to the top.
    if (messageScrollableContainerRef.current && messageScrollableContainerRef.current.scrollTop > 0) {
      messageScrollableContainerRef.current.scrollTop = 0;
    }
  }, [currentMessageId, currentPage]);

  // Creates a scoring instance for the current message.
  const scoring = useMemo(
    () => createScoring(currentMessage.slug, currentMessage.label, customerResponseScoringRules),
    [currentMessage],
  );

  // Filter out pages that are not shown based on scoring rules and exclude confirmation page.
  const pages = useMemo(
    () => currentMessage.pages.filter((page) => scoring.shouldShowMessagePage(page.slug)),
    [scoring],
  );

  const confirmationPage = useMemo(
    () =>
      currentPage &&
      pages.find((page) => page.pageType === 'confirmation' && page.relatedPageSlug === currentPage.slug),
    [currentPage],
  );

  const changeCallbackRequestToConfirmation = () => {
    if (confirmationPage) {
      selectPage(confirmationPage.id);
    }
  };

  // Divide pages for slice pages and simple pages. Slice pages are shown only in index pages of message.
  const slicePages = useMemo(() => pages.filter((page) => page.isMessageSlice), [pages]);
  const simplePages = useMemo(
    () => pages.filter((page) => !page.isMessageSlice && page.pageType !== 'confirmation'),
    [pages],
  );

  // Dynamically selects the first page if there is only one page left.
  useEffect(() => {
    if (isSinglePage(simplePages) && slicePages.length === 0) {
      const pageId = simplePages[0].id;
      if (currentPage === undefined || currentPage.id !== pageId) {
        selectPage(pageId);
      }
    }
  }, [simplePages]);

  const renderSlicePages = () => {
    if ((isSinglePage(pages) && isSinglePage(simplePages)) || currentPage || isEmpty(slicePages)) {
      return null;
    }
    return (
      <>
        {slicePages.map((slicePage) => (
          <Page key={slicePage.id} page={slicePage} />
        ))}
        <hr className="rcw-message-divider" />
      </>
    );
  };

  const renderPageButton = (page) => {
    if (page.type === 'confirmation') {
      return null;
    }
    return (
      <PageButton
        key={'message-page-button-' + page.id}
        page={page}
        onClick={() => {
          analytics.trackPageButtonPressed({ pageId: page.id, pageCaption: page.caption });
          selectPage(page.id);
          setResponsesGroupHidden(true);
        }}
      ></PageButton>
    );
  };

  const renderPageButtons = () => {
    if (currentPage && isShowSiblingPageButtonsEnabled) {
      if (
        currentPage.pageType === 'form' ||
        currentPage.pageType === 'callback-request' ||
        currentPage.pageType === 'confirmation'
      ) {
        // Don't show siblings page buttons on opened form,
        // callback-request, confirmation page type buttons.
        return null;
      } else {
        return simplePages.map((page) => (page.id !== currentPage.id ? renderPageButton(page) : null));
      }
    } else if (!currentPage) {
      return simplePages.map((page) => renderPageButton(page));
    } else {
      return null;
    }
  };

  const renderMessageContent = () => {
    // Container should not have any margins on the left side.
    const scrollbarWidth = messageScrollableContainerRef.current
      ? messageScrollableContainerRef.current.offsetWidth - messageScrollableContainerRef.current.clientWidth
      : 0;

    return (
      <div
        ref={messageScrollableContainerRef}
        style={{ '--calculated-scrollbar-width': scrollbarWidth + 'px' } as CSSProperties}
        key="rcw-message-scrollable-container"
        className="rcw-message-scrollable-container"
        data-cy="message-scrollable-container"
      >
        <div ref={messageContentRef} className={`rcw-message-content`}>
          <h3 className="rcw-message-title">{currentMessage.label}</h3>
          {renderSlicePages()}
          <Page page={currentPage} callback={() => changeCallbackRequestToConfirmation()} />
          {!isSinglePage(simplePages) && currentPage && isShowSiblingPageButtonsEnabled && (
            <hr className="rcw-message-divider" />
          )}

          {renderPageButtons()}
        </div>
      </div>
    );
  };

  return (
    <div className="rcw-message" id={'message-' + currentMessage.slug}>
      {renderMessageContent()}

      <div
        className="rcw-empty-space-filler"
        // Div element that plays the role of an empty space filler between Message and Responses during
        // animation, or on mobile devices when the height is fixed and the content has short height.
        // It allows Responses component to be always in bottom of component.
      />

      <div
        ref={actionsContainerRef}
        key="rcw-actions-container"
        className="rcw-actions-container"
        // Container is used for any actions which handle the content in message.
        // This container also participates in changing the height of the widget
      >
        <SliderNavigation />
      </div>
      <Responses scoring={scoring} />
    </div>
  );
}

export default Message;
