import React, { CSSProperties, useEffect } from 'react';
import { useDataContext } from 'src/context/data';
import Testimonials from '../Conversation/Testimonials/Testimonials';
import { MIN_Z_INDEX } from 'src/utils/hooks/useZIndex';

function CompactLauncher() {
  const {
    appState: {
      widgetIsOpen,
      widgetData: { parameters, config },
    },
    makeWidgetReset,
    setWidgetOpenedStatus,
  } = useDataContext();

  useEffect(() => {
    if (!widgetIsOpen) {
      makeWidgetReset();
    }
  }, [widgetIsOpen]);

  return (
    <div
      className={`rcw-compact-launcher ${widgetIsOpen ? 'inactive' : 'active'}`}
      style={
        {
          '--collapsed-button-background-color': parameters.collapsedButtonBackgroundColor,
          '--collapsed-button-text-color': parameters.collapsedButtonTextColor,
          '--widget-z-index': MIN_Z_INDEX,
        } as CSSProperties
      }
      onClick={() => {
        setWidgetOpenedStatus(true);
      }}
    >
      <div className="rcw-compact-launcher-content">
        <Testimonials maxCollapsedWidth={230} parameters={parameters} dialogConfig={config} collapsed />
      </div>
      <div className="rcw-compact-launcher-circle"></div>
    </div>
  );
}

export default CompactLauncher;
