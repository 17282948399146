import {
  ArithmeticOperator,
  BuiltinOperand,
  ComparisonOperator,
  LogicalOperator,
  tokenize,
  TokenKind,
} from './tokenizer';
import { transform } from './transformers';

/** Union type representing an operand on the stack. */
type Operand = number | boolean;

/** Type-safe operand stack for rule evaluation. */
class OperandStack {
  #data: Operand[];

  constructor() {
    this.#data = [];
  }

  /** Pushes an operand onto the stack. */
  push(operand: Operand): void {
    this.#data.push(operand);
  }

  /**
   * Pops and returns the top operand from the stack.
   *
   * @throws Will throw an error if the stack is empty.
   */
  #pop(): Operand {
    const operand = this.#data.pop();
    if (operand === undefined) {
      throw new Error('Missing operand');
    }
    return operand;
  }

  /**
   * Pops and returns a numeric operand.
   *
   * @throws Will throw an error if the operand is not a number.
   */
  popNumber(): number {
    const operand = this.#pop();
    if (typeof operand !== 'number') {
      throw new Error(`Numeric operand expected, but received: ${operand}`);
    }
    return operand;
  }

  /**
   * Pops and returns a boolean operand.
   *
   * @throws Will throw an error if the operand is not a boolean.
   */
  popBoolean(): boolean {
    const operand = this.#pop();
    if (typeof operand !== 'boolean') {
      throw new Error(`Boolean operand expected, but received: ${operand}`);
    }
    return operand;
  }
}

/** Represents a single instruction that operates on the operand stack. */
type Instruction = (stack: OperandStack) => undefined;

/** Maps arithmetic operators to their corresponding instruction functions. */
const ArithmeticOperatorInstructionMap: Record<ArithmeticOperator, Instruction> = {
  [ArithmeticOperator.Add]: (stack) => {
    stack.push(stack.popNumber() + stack.popNumber());
  },
  [ArithmeticOperator.Multiply]: (stack) => {
    stack.push(stack.popNumber() * stack.popNumber());
  },
  [ArithmeticOperator.Subtract]: (stack) => {
    const b = stack.popNumber();
    const a = stack.popNumber();
    stack.push(a - b);
  },
};

/** Maps comparison operators to their corresponding instruction functions. */
const ComparisonOperatorInstructionMap: Record<ComparisonOperator, Instruction> = {
  [ComparisonOperator.Equal]: (stack) => {
    stack.push(stack.popNumber() === stack.popNumber());
  },
  [ComparisonOperator.GreaterThan]: (stack) => {
    const b = stack.popNumber();
    const a = stack.popNumber();
    stack.push(a > b);
  },
  [ComparisonOperator.GreaterThanOrEqual]: (stack) => {
    const b = stack.popNumber();
    const a = stack.popNumber();
    stack.push(a >= b);
  },
  [ComparisonOperator.LessThan]: (stack) => {
    const b = stack.popNumber();
    const a = stack.popNumber();
    stack.push(a < b);
  },
  [ComparisonOperator.LessThanOrEqual]: (stack) => {
    const b = stack.popNumber();
    const a = stack.popNumber();
    stack.push(a <= b);
  },
  [ComparisonOperator.NotEqual]: (stack) => {
    const b = stack.popNumber();
    const a = stack.popNumber();
    stack.push(a !== b);
  },
};

/** Maps logical operators to their corresponding instruction functions. */
const LogicalOperatorInstructionMap: Record<LogicalOperator, Instruction> = {
  [LogicalOperator.And]: (stack) => {
    const b = stack.popBoolean();
    const a = stack.popBoolean();
    stack.push(a && b);
  },
  [LogicalOperator.Or]: (stack) => {
    const b = stack.popBoolean();
    const a = stack.popBoolean();
    stack.push(a || b);
  },
};

/** Maps built-in operands to their corresponding push instruction functions. */
const BuiltinOperandInstructionMap: Record<BuiltinOperand, (bridge: Bridge) => Instruction> = {
  [BuiltinOperand.Total]: (bridge) => (stack) => {
    stack.push(bridge.getTotalScore());
  },
};

/** Interface for external data access. */
export interface Bridge {
  /**
   * Gets the score for a specific message, or sums the scores of messages that start
   * with the provided slug prefix.
   *
   * @param slug - The exact message slug or a prefix if `isPrefix` is true.
   * @param isPrefix - Indicates if the slug is a prefix.
   * @returns The score or the sum of scores for matching messages.
   */
  getMessageScore(slug: string, isPrefix: boolean): number;

  /** Gets the current total score. */
  getTotalScore(): number;
}

/** Creates an instruction to push a message slug's score onto the stack. */
const createPushMessageScoreInstruction =
  (bridge: Bridge, slug: string, isPrefix: boolean): Instruction =>
  (stack) => {
    stack.push(bridge.getMessageScore(slug, isPrefix));
  };

/** Creates an instruction to push a numeric literal onto the stack. */
const createPushNumberInstruction =
  (value: number): Instruction =>
  (stack) => {
    stack.push(value);
  };

/** Represents a compiled rule, which is a function that returns a boolean. */
export type CompiledRule = () => boolean;

/**
 * Compiles the input string rule into an executable function.
 *
 * @param input - The input string representing the rule.
 * @param bridge - The interface to access external data for evaluation.
 * @returns A function that returns a boolean after evaluating the rule.
 * @throws Will throw an error if the compilation fails.
 */
export function compile(input: string, bridge: Bridge): CompiledRule {
  // Tokenize and transform the input rule into a list of instructions.
  const tokens = transform(tokenize(input));
  const instructions: Instruction[] = [];

  // Process each token and generate the corresponding instruction.
  for (const token of tokens) {
    switch (token.kind) {
      case TokenKind.ArithmeticOperator:
        instructions.push(ArithmeticOperatorInstructionMap[token.value]);
        break;
      case TokenKind.ComparisonOperator:
        instructions.push(ComparisonOperatorInstructionMap[token.value]);
        break;
      case TokenKind.LogicalOperator:
        instructions.push(LogicalOperatorInstructionMap[token.value]);
        break;
      case TokenKind.BuiltinOperand:
        instructions.push(BuiltinOperandInstructionMap[token.value](bridge));
        break;
      case TokenKind.MessageSlug:
        instructions.push(createPushMessageScoreInstruction(bridge, token.value, token.isPrefix));
        break;
      case TokenKind.NumericLiteral:
        instructions.push(createPushNumberInstruction(token.value));
        break;
    }
  }
  // Compile the instructions into a single rule.
  return () => {
    // Create an operand stack to store intermediate results.
    const stack = new OperandStack();
    // Evaluate the expression by applying the instructions to the operand stack.
    for (const instruction of instructions) {
      instruction(stack);
    }
    // Return the final result from the operand stack.
    return stack.popBoolean();
  };
}
