import { ConsentSolution } from 'src/api/types';

export const COOKIE_CONSENT_CUSTOM_EVENT_NAME = 'dancecard-cookie-consent';

declare global {
  interface Window {
    CLI?: { allowedCategories?: string[]; cookieLawInfoRunCallBacks?: Function };
    getCkyConsent?: () => { categories: Record<string, boolean> };
  }
}

// -------------------------------- //

let consentSolution: ConsentSolution = 'no-consent-solution';
let consentCategory: string | undefined;
let consentEvent: Event;
let originalFunction: () => void;

// -------------------------------- //

function consentCategoryNotMatchErrorMessage() {
  console.error(
    `The consent category ${consentCategory} does not match any category from the ${consentSolution} banner`,
  );
}

// -------------------------------- //

function createConsentEvent() {
  consentEvent = new CustomEvent(COOKIE_CONSENT_CUSTOM_EVENT_NAME, {
    bubbles: true,
  });
}

// -------------------------------- //

function checkCookieLawInfoBannerIsOpen(): boolean {
  const bannerElement = document.getElementById('cookie-law-info-bar');
  if (!bannerElement) {
    return false;
  }
  return bannerElement.style.display !== 'none';
}

// Used to control z-index style of the widget to avoid possible collisions with elements on the page.
export function checkCookieBannerIsOpen(): boolean {
  switch (consentSolution) {
    case 'no-consent-solution':
      return false;

    case 'cookie-law-info':
      return checkCookieLawInfoBannerIsOpen();

    case 'cookie-yes':
      return false;
  }
}

// -------------------------------- //

function checkCookieLawInfoAllowedCategories(): boolean {
  if (window.CLI?.allowedCategories) {
    if (!consentCategory) {
      return false;
    }
    const allowedCategories: string[] = window.CLI.allowedCategories;
    return allowedCategories.some(i => i === consentCategory);
  } else {
    console.error('There is no cookieLawInfo interface in window object (window.CLI)');
    return false;
  }
}

function checkCookieyesAllowedCategories(): boolean {
  if (window.getCkyConsent) {
    if (!consentCategory) {
      return false;
    }
    const cookieyesConsent = window.getCkyConsent();
    if (!(consentCategory in cookieyesConsent.categories)) {
      consentCategoryNotMatchErrorMessage();
      return false;
    }
    return cookieyesConsent.categories[consentCategory];
  } else {
    console.error('There is no cookieyes function in window object (window.getCkyConsent())');
    return false;
  }
}

function cookieLawInfoRunCallbacks() {
  originalFunction();
  document.dispatchEvent(consentEvent);
}

function setCookieyesEventListener() {
  document.addEventListener('cookieyes_consent_update', function(eventData: CustomEvent) {
    document.dispatchEvent(consentEvent);
  });
}

function setCookieLawInfoCallback() {
  if (window.CLI?.cookieLawInfoRunCallBacks && window.CLI.cookieLawInfoRunCallBacks !== cookieLawInfoRunCallbacks) {
    originalFunction = window.CLI.cookieLawInfoRunCallBacks.bind(window.CLI);
    window.CLI.cookieLawInfoRunCallBacks = cookieLawInfoRunCallbacks;
  }
}

export function setConsentSolution(name: ConsentSolution, category: string) {
  if (name !== 'no-consent-solution') {
    consentSolution = name;
    consentCategory = category;
    createConsentEvent();
  }

  if (name === 'cookie-law-info') {
    setCookieLawInfoCallback();
  }

  if (name === 'cookie-yes') {
    setCookieyesEventListener();
  }
}

export function shouldWidgetCollectPersonalData() {
  switch (consentSolution) {
    case 'no-consent-solution':
      return true;

    case 'cookie-law-info':
      return checkCookieLawInfoAllowedCategories();

    case 'cookie-yes':
      return checkCookieyesAllowedCategories();
  }

  return false;
}
