import React from 'react';

import { renderInfoIcon } from 'src/utils/iconRenderers';
import * as colors from 'src/utils/styles/colors.module.scss';

type InformationMessageTypes = {
  children: string;
};

function InformationMessage({ children }: InformationMessageTypes) {
  return (
    <div className="rcw-pdc-form-was-sent">
      {renderInfoIcon(colors.text)}
      {children}
    </div>
  );
}

export default InformationMessage;
