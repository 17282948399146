// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export var borderRadiusBasic = `14px`;
export var borderRadiusCircleRound = `20px`;
export var mobileBreakpoint = `450`;
export var laptopBreakpoint = `1366`;
export var bigLaptopBreakpoint = `1550`;
export default ___CSS_LOADER_EXPORT___;
