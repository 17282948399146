import React from 'react';
import { WidgetData } from 'src/api/types';
import { DataContextType } from 'src/context/data/types';
import { useDataContext } from 'src/context/data';
import { useLayoutStateContext } from 'src/context/layoutState';
import analytics from 'src/utils/analytics/analytics';
import { iframeShowcaseContainer, imgStyles } from './styles';
import { Block } from 'src/utils/common';

type TextBlockProps = {
  block: Block;
};

const imageReg = /[\.](gif|jpg|jpeg|tiff|png)$/i;
const pdfReg = /[\.](pdf)$/i;
const htmlReg = /^html:/i;

function TextBlock({ block }: TextBlockProps) {
  const dataContext: DataContextType = useDataContext();
  const { setLoading, toggleModalIsVisible, setModalChild } = useLayoutStateContext();

  const renderIframeShowcase = src => {
    return (
      <div style={iframeShowcaseContainer}>
        <iframe
          src={src}
          width="100%"
          height="100%"
          onLoad={() => {
            setLoading(false);
          }}
        ></iframe>
      </div>
    );
  };

  const renderHtmlDocument = src => {
    const slug = src.slice(5).trim(); // length of 'html:'
    const widgetData: WidgetData = dataContext.appState.widgetData;
    const htmlDocument = widgetData.config.htmlDocuments[slug];

    return (
      <div style={iframeShowcaseContainer}>
        <iframe
          srcDoc={htmlDocument || '404 Not Found'}
          sandbox="allow-same-origin allow-popups allow-scripts"
          width="100%"
          height="100%"
          onLoad={() => {
            setLoading(false);
          }}
        ></iframe>
      </div>
    );
  };

  const renderImg = src => {
    return (
      <img
        src={src}
        style={imgStyles}
        onLoad={() => {
          setLoading(false);
        }}
      ></img>
    );
  };

  switch (block.type) {
    case 'text':
      return <>{block.text}</>;

    case 'link':
      if (imageReg.test(block.url)) {
        return (
          <a
            href="#"
            onClick={event => {
              event.preventDefault();
              setModalChild(renderImg(block.url));
              analytics.trackLinkOpened({ linkTitle: block.text, linkURL: block.url });
              toggleModalIsVisible(true);
            }}
          >
            {block.text}
          </a>
        );
      }

      if (pdfReg.test(block.url)) {
        return (
          <a
            href={block.url}
            target="_blank"
            onClick={() => {
              analytics.trackLinkOpened({ linkTitle: block.text, linkURL: block.url });
            }}
          >
            {block.text}
          </a>
        );
      }

      if (htmlReg.test(block.url)) {
        return (
          <a
            href="#"
            onClick={event => {
              event.preventDefault();
              setModalChild(renderHtmlDocument(block.url));
              analytics.trackLinkOpened({ linkTitle: block.text, linkURL: block.url });
              toggleModalIsVisible(true);
            }}
          >
            {block.text}
          </a>
        );
      }

      if (block.options === 'target="_blank"') {
        return (
          <a
            href={block.url}
            target="_blank"
            onClick={event => {
              analytics.trackLinkOpened({ linkTitle: block.text, linkURL: block.url });
            }}
          >
            {block.text}
          </a>
        );
      }

      return (
        <a
          href="#"
          onClick={event => {
            event.preventDefault();
            setModalChild(renderIframeShowcase(block.url));
            analytics.trackLinkOpened({ linkTitle: block.text, linkURL: block.url });
            toggleModalIsVisible(true);
          }}
        >
          {block.text}
        </a>
      );

    default:
      return null;
  }
}

export default TextBlock;
