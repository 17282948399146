import React from 'react';
import { renderPlayIcon } from 'src/utils/iconRenderers';

type WistiaThumbnailProps = {
  thumbnailUrl: string;
  onClick?: () => void;
  width: number;
  isPlayButtonDisplayed?: boolean;
};

function WistiaThumbnail({ thumbnailUrl, onClick, width, isPlayButtonDisplayed }: WistiaThumbnailProps) {
  if (!thumbnailUrl) {
    return null;
  }

  const url = new URL(thumbnailUrl);
  url.searchParams.set('image_resize', width.toString());
  const imageSrc = url.toString();

  return (
    <div key={imageSrc} className="wistia-thumbnail-container" onClick={onClick} data-cy="wistia-thumbnail-container">
      {isPlayButtonDisplayed && <div className="rcw-video-play-button">{renderPlayIcon()}</div>}
      <img src={imageSrc} alt="Wistia Preview" />
    </div>
  );
}

export default WistiaThumbnail;
