import React from 'react';
import isEmpty from 'lodash/isEmpty';

import SVG from 'react-inlinesvg';

import { ConfirmationPage } from 'src/api/types';
import { useDataContext } from 'src/context/data';

import LottieAnimation from 'src/components/LottieAnimation/LottieAnimation';
import TextBuilder from '../TextBuilder/TextBuilder';
import { formatPhoneNumber } from 'src/utils/common';
import { getSubmittedCallbackRequestFormData } from 'src/utils/persistentStorage';

type PageCallbackRequestProps = {
  page: ConfirmationPage;
};

function PageConfirmation({ page }: PageCallbackRequestProps) {
  const {
    appState: {
      assets,
      widgetData: {
        parameters: { robotBackgroundColor, robotTextColor, robotBorderColor },
      },
      currentMessageId,
    },
  } = useDataContext();

  const formData = getSubmittedCallbackRequestFormData(page.relatedPageSlug);

  const assetContent = page.asset && assets[page.asset.id];

  const text = page.contentBody;

  const isFull = Boolean(page.asset || text?.length >= 100);

  const footnoteText = page.footnote;

  return (
    <div
      id={page.slug}
      className={`rcw-page-body ${isFull ? 'rcw-page-body_full' : ''}`}
      key={'page-body-' + currentMessageId}
      style={
        isFull ? {} : { backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }
      }
    >
      {page.asset && page.asset.type === 'animation-lottie' && (
        <div className="rcw-lottie-container">
          <LottieAnimation className="rcw-lottie-animation" data={assetContent} />
        </div>
      )}
      {page.asset && page.asset.type === 'image-svg' && (
        <div className="rcw-svg-container">
          <SVG src={assetContent} />
        </div>
      )}
      <TextBuilder text={text} />
      <hr className="rcw-message-divider" />
      <div className="rcw-message-page-confirmation-form-data">
        <p>Phone: {formatPhoneNumber(formData.phoneNumber)}</p>
        <p>Preferred call time: {formData.preferredCallTime}</p>
      </div>
      <hr className="rcw-message-divider" />
      {!isEmpty(footnoteText) && <p className="rcw-page-text-footnote">{footnoteText}</p>}
    </div>
  );
}

export default PageConfirmation;
