import React from 'react';

export const spinner = {
  width: '60px',
  height: '60px',
  border: '10px solid #f3f3f3',
  borderTop: '10px solid #3498db',
  borderRadius: '50%',
  animation: 'rcwSpinnerFrames 1s linear infinite',
} as React.CSSProperties;

type SpinnerLoaderProps = {
  isLoading: boolean;
};

function SpinnerLoader({ isLoading }: SpinnerLoaderProps) {
  if (!isLoading) {
    return null;
  }

  return <div className="rcw-spinner-loader" style={spinner} />;
}

export default SpinnerLoader;
