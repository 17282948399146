const ELEMENT_ID = 'dancecard-leadnurturing-chatbot';

export function getHost(): HTMLElement {
  const host = document.getElementById(ELEMENT_ID);
  if (host) {
    return host;
  }
  const newHost = document.createElement('div');
  newHost.setAttribute('id', ELEMENT_ID);
  document.body.appendChild(newHost);
  return newHost;
}
