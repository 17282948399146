import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Markdown from 'react-markdown';
import { parseTextToExtractMarkdownLinks } from 'src/utils/common';
import TextBlock from './TextBlock';

type TextBuilderProps = {
  text: string;
  isMarkdown?: boolean;
};

function TextBuilder({ text, isMarkdown }: TextBuilderProps) {
  if (isMarkdown) {
    return <Markdown className="rcw-page-text">{text}</Markdown>;
  }

  const paragraphs = text.split(/\r?\n/).filter((str) => !isEmpty(str));
  const parsedParagraphs = paragraphs.map((item) => parseTextToExtractMarkdownLinks(item));

  return (
    <div className="rcw-page-text">
      {parsedParagraphs.map((p, i) => (
        <p key={'paragraph' + i} className="rcw-page-text-paragraph">
          {p.map((t, j) => (
            <React.Fragment key={'page-text-' + i + '_' + j}>
              <TextBlock block={t} />
            </React.Fragment>
          ))}
        </p>
      ))}
    </div>
  );
}

export default TextBuilder;
