import React, { useEffect } from 'react';
import { WistiaVideoEndEvent, WistiaVideoPauseEvent, WistiaVideoPlayEvent } from './types';

type WistiaIframeMobilePlayerProps = {
  matcher: string;
  onPlay: (event: WistiaVideoPlayEvent) => void;
  onPause: (event: WistiaVideoPauseEvent) => void;
  onEnd: (event: WistiaVideoEndEvent) => void;
};

function WistiaIframeMobilePlayer({ matcher, onPlay, onPause, onEnd }: WistiaIframeMobilePlayerProps) {
  if (!matcher) {
    return null;
  }

  const embedWistiaConfig = {
    id: 'dancecard-' + matcher,
    hashedId: matcher,
    onReady: function (video) {
      video.bind('play', () => onPlay({ matcher, name: video.name(), time: video.time() }));
      video.bind('pause', () => onPause({ matcher, name: video.name(), time: video.time() }));
      video.bind('end', () => onEnd({ matcher, name: video.name() }));
      video.bind('beforeremove', () => {
        return () => video.unbind();
      });
    },
  };

  useEffect(() => {
    const { _wq } = window;
    if (_wq) {
      _wq.push(embedWistiaConfig);
    }

    return () => {
      if (_wq) {
        _wq.push({ revoke: embedWistiaConfig });
      }
    };
  }, [matcher]);

  return (
    <iframe
      id={'dancecard-' + matcher}
      allow="fullscreen"
      title="Message video embedded"
      src={`//fast.wistia.net/embed/iframe/${matcher}?videoFoam=true&playsinline=false&fullscreenButton=true`}
      className="wistia_embed"
      name="wistia_embed"
      data-cy="wistia-video-iframe"
    />
  );
}

export default WistiaIframeMobilePlayer;
