import sample from 'lodash/sample';
import { DialogConfig, SplitTestGroup, SplitTestMode } from 'src/api/types';

export function getRandomTestVariant(mode: SplitTestMode): SplitTestGroup {
  switch (mode) {
    case 'a-b':
      return sample(['control-a', 'variant-b']);

    case 'a-b-c':
      return sample(['control-a', 'variant-b', 'variant-c']);

    case 'no-split-test':
      return 'none';
  }
}

export function checkIfLottieAssetsExist(script: DialogConfig['script']): boolean {
  return Object.values(script).some((message) =>
    message.pages.some((page) => 'asset' in page && page.asset?.type === 'animation-lottie'),
  );
}
