import React, { useState, useEffect } from 'react';
import { useDataContext } from '../context/data';

export function BotStyleCustomization() {
  const context = useDataContext();

  const [customCss, setCustomCss] = useState<string>(context.appState.widgetData.css);

  useEffect(() => setCustomCss(context.appState.widgetData.css), [context.appState.widgetData.css]);

  window.DancecardMicrosite._customCSS = customCss;
  window.DancecardMicrosite._setCustomCSS = setCustomCss;

  if (!customCss) {
    return null;
  }

  return <style type="text/css">{customCss}</style>;
}
