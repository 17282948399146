import React from 'react';
import { createPortal } from 'react-dom';
import { shouldUseShadowDom } from './shadowDom';
import { getHost } from './host';

type PortalSlotProps = {
  children: JSX.Element;
  slotName: string;
};

// PortalSlot is used to allow the Wistia script to bind changes to the video player outside of the shadow DOM.

function PortalSlot({ children, slotName }: PortalSlotProps) {
  const host = getHost();
  const isShadowDom = shouldUseShadowDom();

  if (host && isShadowDom) {
    return (
      <>
        {createPortal(<div slot={slotName}>{children}</div>, host)}
        <slot name={slotName} />
      </>
    );
  }

  return children;
}

export default PortalSlot;
