import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import SVG from 'react-inlinesvg';

import { CallbackRequestPage } from 'src/api/types';
import { useDataContext } from 'src/context/data';

import CallbackRequestForm from 'src/components/PersonalDataCaptureForm/CallbackRequestForm';
import LottieAnimation from 'src/components/LottieAnimation/LottieAnimation';
import TextBuilder from '../TextBuilder/TextBuilder';
import { isCallbackRequestFormSubmitted } from 'src/utils/persistentStorage';

type PageCallbackRequestProps = {
  page: CallbackRequestPage;
  onFormSubmit: () => void;
};

function PageCallbackRequest({ page, onFormSubmit }: PageCallbackRequestProps) {
  const {
    appState: {
      assets,
      widgetData: {
        parameters: { robotBackgroundColor, robotTextColor, robotBorderColor },
      },
      currentMessageId,
    },
  } = useDataContext();

  const [isCallbackRequestFormVisible, setCallbackRequestFormIsVisible] = useState(
    !isCallbackRequestFormSubmitted(page.slug),
  );

  useEffect(() => {
    if (!isCallbackRequestFormVisible) {
      onFormSubmit();
    }
  }, []);

  const assetContent = page.asset && assets[page.asset.id];

  const text = page.contentBody;

  const isFull = Boolean(page.asset || text?.length >= 100);

  const footnoteText = page.footnote;

  return (
    <div
      id={page.slug}
      className={`rcw-page-body ${isFull ? 'rcw-page-body_full' : ''}`}
      key={'page-body-' + currentMessageId}
      style={
        isFull ? {} : { backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }
      }
    >
      {page.asset && page.asset.type === 'animation-lottie' && (
        <div className="rcw-lottie-container">
          <LottieAnimation className="rcw-lottie-animation" data={assetContent} />
        </div>
      )}

      {page.asset && page.asset.type === 'image-svg' && (
        <div className="rcw-svg-container">
          <SVG src={assetContent} />
        </div>
      )}

      <TextBuilder text={text} />

      {!isEmpty(footnoteText) && <p className="rcw-page-text-footnote">{footnoteText}</p>}

      {isCallbackRequestFormVisible && (
        <CallbackRequestForm
          onDone={(value) => {
            if (value) {
              onFormSubmit();
            }
            setCallbackRequestFormIsVisible(false);
          }}
          pageSlug={page.slug}
        />
      )}
    </div>
  );
}

export default PageCallbackRequest;
