import { shouldUseShadowDom } from './shadowDom';
import { getHost } from './host';

export const getTargetElements = (() => {
  const host = getHost();

  const isShadowDom = shouldUseShadowDom();

  let styleSlot: HTMLDivElement | null = null;
  let renderIn: HTMLDivElement | null = null;

  return () => {
    if (host && styleSlot === null && renderIn === null) {
      styleSlot = document.createElement('div');
      renderIn = document.createElement('div');

      if (isShadowDom) {
        const shadow = host.attachShadow({ mode: 'open' });
        shadow.appendChild(styleSlot);
        shadow.appendChild(renderIn);
      } else {
        host.appendChild(styleSlot);
        host.appendChild(renderIn);
      }
    }

    return { styleSlot, renderIn };
  };
})();
