import { Response } from 'src/api/types';

export const createResponseGroups = (responses: Response[], responsesPerGroup: number) => {
  const numberOfGroups = Math.ceil(responses.length / responsesPerGroup);
  return Array.from({ length: numberOfGroups }, (_, i) => {
    const start = i * responsesPerGroup;
    const end = start + responsesPerGroup;
    return responses.slice(start, end);
  });
};
