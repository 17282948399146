import React, { useLayoutEffect, useState } from 'react';

import { sendCapturedPersonalData } from 'src/api';
import { setDataToSessionStorage, KEYS } from 'src/utils/persistentStorage';
import { useDataContext } from 'src/context/data';
import useForm from 'src/utils/hooks/useForm';
import analytics from 'src/utils/analytics/analytics';
import LottieAnimation from 'src/components/LottieAnimation/LottieAnimation';

import { Steps, OwnProps, FieldsProps } from './types';

import * as colors from 'src/utils/styles/colors.module.scss';
import successAnimation from '../../../assets/animations/success_animation.json';

import Input from './Input/Input';

function CapturePersonalDataForm({ onDone }: OwnProps) {
  const {
    appState: {
      widgetData: {
        parameters: { titleBackgroundColor, titleFontColor },
        config: {
          personalDataCaptureParameters: { isPostalCodeFieldEnabled, isAgeFieldEnabled, isGenderFieldEnabled },
        },
      },
    },
    importLottieReact,
  } = useDataContext();

  const [currentStep, setCurrentStep] = useState<Steps>(Steps.init);
  const [isRequestErrorVisible, setRequestErrorVisible] = useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);

  useLayoutEffect(() => {
    // Capture Personal Data Form has an lottie-animation after submitting the form.
    importLottieReact();
  }, []);

  const handlePersonalDataCaptureFormStatus = (status: 'skipped' | 'submitted') => {
    if (status === 'submitted') {
      setDataToSessionStorage(KEYS.isPDCFormSubmitted, true);
      analytics.trackPdcFormSubmitted();
    } else {
      analytics.trackPdcFormSkipped();
    }

    onDone();
  };

  const {
    handleSubmit,
    handleChange,
    data: user,
    errors,
  } = useForm<FieldsProps>({
    // TODO: Make validation rules a separate constant
    validations: {
      firstName: {
        required: {
          value: true,
          message: 'First name is required.',
        },
      },
      lastName: {
        required: {
          value: true,
          message: 'Last name is required.',
        },
      },
      age: isAgeFieldEnabled
        ? {
            custom: {
              isValid: (value) => parseInt(value, 10) > 0 && parseInt(value, 10) <= 150,
              message: 'Age is required.',
            },
            required: {
              value: true,
              message: 'Age is required.',
            },
          }
        : undefined,
      email: {
        pattern: {
          value: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$',
          message: 'Email is required',
        },
        required: {
          value: true,
          message: 'Email is required.',
        },
      },
      postalCode: isPostalCodeFieldEnabled
        ? {
            pattern: {
              value: '.{5,7}',
              message: 'Valid postal code required.',
            },
            required: {
              value: true,
              message: 'Postal code code is required.',
            },
          }
        : undefined,
      gender: isGenderFieldEnabled
        ? {
            required: {
              value: true,
              message: 'The gender field is required.',
            },
          }
        : undefined,
    },
    onSubmit: () => {
      setIsSending(true);
      sendCapturedPersonalData({
        body: {
          source: 'microsite',
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          age: user.age,
          postalCode: user.postalCode,
          gender: user.gender,
        },
        onSuccess: () => {
          if (isRequestErrorVisible) {
            setRequestErrorVisible(false);
          }
          setCurrentStep(Steps.thanks);
          setIsSending(false);
          setTimeout(() => {
            handlePersonalDataCaptureFormStatus('submitted');
          }, 3000);
        },
        onError: (err) => {
          console.error(err);
          setRequestErrorVisible(true);
          setIsSending(false);
        },
      });
    },
  });

  const renderStep = () => {
    const stepsMap = {
      [Steps.init]: (
        <form className="rcw-form" onSubmit={handleSubmit}>
          <Input
            id="First-name-captured-field"
            type="text"
            placeholder="First name"
            value={user.firstName || ''}
            onChange={handleChange('firstName')}
            data-cy="capture-personal-data-input-first-name"
            color={titleBackgroundColor}
            isInvalid={!!errors.firstName}
          />

          <Input
            id="Last-name-captured-field"
            type="text"
            placeholder="Last name"
            value={user.lastName || ''}
            onChange={handleChange('lastName')}
            data-cy="capture-personal-data-input-last-name"
            color={titleBackgroundColor}
            isInvalid={!!errors.lastName}
          />

          <div className="rcw-form-horizontal-input-group">
            {isGenderFieldEnabled && (
              <select
                className={`rcw-capture-personal-data-select`}
                style={{
                  outlineColor: Boolean(errors.gender) ? colors.error : titleBackgroundColor,
                  borderColor: Boolean(errors.gender) ? colors.error : titleBackgroundColor,
                  color: user.gender ? colors.text : '#9098a9',
                }}
                onChange={handleChange('gender')}
                defaultValue="Gender"
                data-cy="capture-personal-data-input-gender"
              >
                <option disabled>Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            )}
            {isAgeFieldEnabled && (
              <Input
                id="Age-captured-field"
                type="number"
                placeholder="Age"
                value={user.age || ''}
                onChange={handleChange<number>('age', (value) => parseInt(value, 10))}
                inputMode="numeric"
                data-cy="capture-personal-data-input-age"
                color={titleBackgroundColor}
                isInvalid={!!errors.age}
                min={1}
                max={150}
                maxLength={3}
              />
            )}
          </div>

          <Input
            type="text"
            placeholder="Email address"
            inputMode="email"
            value={user.email || ''}
            onChange={handleChange('email')}
            data-cy="capture-personal-data-input-email"
            color={titleBackgroundColor}
            isInvalid={!!errors.email}
          />
          {isPostalCodeFieldEnabled && (
            <Input
              type="text"
              placeholder="Postal code"
              value={user.postalCode || ''}
              onChange={handleChange<number>('postalCode')}
              data-cy="capture-personal-data-input-postal-code"
              color={titleBackgroundColor}
              isInvalid={!!errors.postalCode}
              maxLength={7}
            />
          )}
          {isRequestErrorVisible && (
            <span className="rcw-form-request-error-message">Form submission error, repeat or skip</span>
          )}
          <div className="rcw-form-buttons-container">
            <button type="button" disabled={isSending} onClick={() => handlePersonalDataCaptureFormStatus('skipped')}>
              Skip
            </button>
            <button
              type="submit"
              className="rcw-capture-personal-data-submit-button"
              style={{ backgroundColor: titleBackgroundColor, color: titleFontColor }}
              disabled={isSending}
              data-cy="capture-personal-data-submit-button"
            >
              {isSending ? 'Sending...' : 'Send'}
            </button>
          </div>
        </form>
      ),
      [Steps.thanks]: (
        <section className="rcw-form-thanks-step">
          <h3>Thank you!</h3>

          <div className="rcw-form-success-animation-wrapper">
            <LottieAnimation data={successAnimation} />
          </div>
        </section>
      ),
    };

    return stepsMap[currentStep];
  };

  return (
    <div className="rcw-capture-personal-data-container" data-cy="capture-personal-data-container">
      {renderStep()}
    </div>
  );
}

export default CapturePersonalDataForm;
