import { Testimonial } from 'src/api/types';

export function constructSamples(credibilityBuilders: string[] | undefined, testimonials: Testimonial[] | undefined) {
  const formattedTestimonials =
    !testimonials || testimonials?.length === 0
      ? []
      : testimonials.map(testimonial => `${testimonial.text} - ${testimonial.author}`);

  const formattedCredibilityBuilders =
    !credibilityBuilders || credibilityBuilders.length === 0
      ? []
      : credibilityBuilders.map(credibilityBuilder => credibilityBuilder);

  const lists = [formattedTestimonials, formattedCredibilityBuilders];
  const longestListIndex = lists.reduce((maxI, el, i, arr) => (el.length > arr[maxI].length ? i : maxI), 0);
  const longestList = lists[longestListIndex];

  const samples: string[] = [];

  for (let i = 0; i < longestList.length; i++) {
    const testimonial = formattedTestimonials[i];
    const credibilityBuilder = formattedCredibilityBuilders[i];

    if (testimonial) {
      samples.push(testimonial);
    }
    if (credibilityBuilder) {
      samples.push(credibilityBuilder);
    }
  }

  return samples;
}
