import { useLayoutEffect, RefObject } from 'react';

function useResizeObserver<T extends HTMLElement>(ref: RefObject<T>, callback: (entry: ResizeObserverEntry) => void) {
  useLayoutEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        // `entry.contentRect.height` and `entry.contentRect.width` don't take into account margin and padding sizes of entry element.
        callback(entry);
      }
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [callback, ref]);
}

export default useResizeObserver;
