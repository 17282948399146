// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export var white = `#fff`;
export var black = `#000`;
export var danger = `#fc9411`;
export var warning = `rgba(255,172,25,.3)`;
export var error = `#fe5f55`;
export var success = `#2cd994`;
export var disabled = `#cccfdb`;
export var text = `#333`;
export var border = `#e6e7ed`;
export var boxShadow = `0px 3px 20px rgba(0,0,0,.1)`;
export default ___CSS_LOADER_EXPORT___;
