import { useEffect, useState } from 'react';

import { useDataContext } from 'src/context/data';

import { COOKIE_CONSENT_CUSTOM_EVENT_NAME, checkCookieBannerIsOpen } from 'src/utils/cookieBanners';

/* - - - - - - - - - - - - - - - - - - - - - - - */

export const MAX_Z_INDEX = 999999;
export const MIN_Z_INDEX = 9990;

/* - - - - - - - - - - - - - - - - - - - - - - - */

// TODO: Find a better name for hook.
const useZIndex = (): number => {
  const {
    appState: { widgetIsOpen },
  } = useDataContext();

  const [isCookieBannerOpen, setIsCookieBannerOpen] = useState(false);

  useEffect(() => {
    setIsCookieBannerOpen(checkCookieBannerIsOpen());
  }, [widgetIsOpen]);

  useEffect(() => {
    const handleConsentUpdate = () => {
      setTimeout(() => {
        setIsCookieBannerOpen(checkCookieBannerIsOpen());
      }, 1000);
    };

    document.addEventListener(COOKIE_CONSENT_CUSTOM_EVENT_NAME, handleConsentUpdate);
    return () => {
      document.removeEventListener(COOKIE_CONSENT_CUSTOM_EVENT_NAME, handleConsentUpdate);
    };
  }, []);

  // zIndex parameter, which take into account low zIndex of CookieLawInfo banner, to show the collapsed Widget behind it.
  // zIndex should be smaller than zIndex of the Modal.
  const zIndexParameter = widgetIsOpen && !isCookieBannerOpen ? MAX_Z_INDEX : MIN_Z_INDEX;

  return zIndexParameter;
};

export default useZIndex;
