import analytics from './analytics';
import MixpanelAnalytics from './MixpanelAnalytics';
import GoogleAnalytics from './GoogleAnalytics';
import integrationId from '../integrationId';
import { COOKIE_CONSENT_CUSTOM_EVENT_NAME, shouldWidgetCollectPersonalData } from '../cookieBanners';
import { KEYS, getDataFromSessionStorage } from '../persistentStorage';

type InitializeAnalyticsArgs = {
  isMixpanelAnalyticsEnabled: boolean;
  isGa4AnalyticsEnabled: boolean;
  ga4MeasurementId: string;
};

function setConsentEventListener() {
  document.addEventListener(COOKIE_CONSENT_CUSTOM_EVENT_NAME, (event) => {
    if (shouldWidgetCollectPersonalData()) {
      if (!analytics.checkIfInitialized()) {
        analytics.init();
      }
    } else {
      if (analytics.checkIfInitialized()) {
        analytics.uninit();
      }
    }
  });
}

export const initializeAnalytics = ({
  isMixpanelAnalyticsEnabled,
  isGa4AnalyticsEnabled,
  ga4MeasurementId,
}: InitializeAnalyticsArgs) => {
  if (isMixpanelAnalyticsEnabled) {
    const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

    if (MIXPANEL_TOKEN) {
      analytics.addAnalyticsProvider(new MixpanelAnalytics(MIXPANEL_TOKEN));
    }
  }

  if (isGa4AnalyticsEnabled && ga4MeasurementId) {
    analytics.addAnalyticsProvider(new GoogleAnalytics(ga4MeasurementId));
  }

  if (shouldWidgetCollectPersonalData()) {
    analytics.init();
  }

  setConsentEventListener();

  analytics.registerIntegrationId({ integrationId });

  const splitTestGroup = getDataFromSessionStorage(KEYS.splitTestGroup);
  analytics.registerSplitTestData({
    splitTestGroup: splitTestGroup,
  });

  const deploymentTag = process.env.REACT_APP_DEPLOYMENT_TAG;
  if (deploymentTag) {
    analytics.registerDeploymentTag({ deploymentTag });
  }
};
