import React from 'react';

export const iframeShowcaseContainer = {
  width: '80vw',
  height: '90vh',
} as React.CSSProperties;

export const imgStyles = {
  maxWidth: '80vw',
  maxHeight: '90vh',
} as React.CSSProperties;
