import React, { createContext, useCallback, useEffect, useReducer } from 'react';

import { DimensionsTypes, DataContextType } from './types';
import { fetchWidgetData, fetchAssets, sendChatbotOpeningSignal } from 'src/api';

import last from 'lodash/last';
import mapValues from 'lodash/mapValues';
import {
  KEYS,
  setDataToSessionStorage,
  getDataFromSessionStorage,
  removeItemFromSessionStorage,
  setInitialQueryParams,
  isInitialQueryParamsExist,
} from 'src/utils/persistentStorage';

import { initializeAnalytics } from 'src/utils/analytics';

import { checkIfLottieAssetsExist, getRandomTestVariant } from './utils';
import { setConsentSolution, shouldWidgetCollectPersonalData } from 'src/utils/cookieBanners';
import { isMobileScreen, isSinglePage, blockBodyScroll, allowBodyScroll } from 'src/utils/common';
import analytics from 'src/utils/analytics/analytics';

import { initialState, appContextReducer } from './reducer';
import { SplitTestGroup } from 'src/api/types';

/* - - - - - - - - - - - - - - - - - - - - - - */

const DataContext = createContext<Partial<DataContextType>>({});

function DataContextProvider({ children }: { children: React.ReactNode }) {
  const [appState, appActionDispatch] = useReducer(appContextReducer, initialState);

  const importLottieReact = async () => {
    if (appState.lottieReact) {
      return;
    }
    const lottieReact = await import('lottie-react');
    appActionDispatch({
      type: 'setLottieReact',
      payload: { lottieReact },
    });
  };

  const setWidgetOpenedStatus = useCallback(
    (statusFlag: boolean) => {
      if (statusFlag) {
        if (shouldWidgetCollectPersonalData()) {
          sendChatbotOpeningSignal({
            onError: (err) => console.error(err),
          });
        }
        if (isMobileScreen()) {
          blockBodyScroll();
        }
      } else {
        allowBodyScroll();
      }
      appActionDispatch({ type: 'setWidgetOpenedStatus', statusFlag });
      setDataToSessionStorage(KEYS.widgetIsOpen, statusFlag);
    },
    [isMobileScreen, appState.widgetIsOpen],
  );

  const goForward = (messageId: string) => {
    const routerHistory = appState.routerHistory as string[];
    const updatedHistory = [...routerHistory, messageId];
    const currentMessage = appState.widgetData?.config.script[messageId];
    const currentPage = isSinglePage(currentMessage?.pages) ? currentMessage?.pages[0] : undefined;

    appActionDispatch({
      type: 'makeNavigationMove',
      payload: {
        currentMessageId: messageId,
        currentMessage: currentMessage,
        currentPage: currentPage,
        routerHistory: updatedHistory,
      },
    });
    setDataToSessionStorage(KEYS.routerHistory, updatedHistory);
  };

  const goBack = () => {
    const routerHistory = appState.routerHistory as string[];
    const updatedHistory = routerHistory.slice(0, -1);
    const lastMessageIdOfUpdatedHistory = last(updatedHistory);
    const currentMessageId = lastMessageIdOfUpdatedHistory
      ? lastMessageIdOfUpdatedHistory
      : appState.widgetData.config.firstMessageId;
    const currentMessage = appState.widgetData.config.script[currentMessageId];
    const currentPage = isSinglePage(currentMessage.pages) ? currentMessage.pages[0] : undefined;

    if (appState.isResponsesGroupHide) {
      appActionDispatch({
        type: 'toggleResponsesGroupVisibility',
        payload: {
          isResponsesGroupHide: false,
        },
      });
      setDataToSessionStorage(KEYS.isResponsesGroupHide, false);
    } else {
      appActionDispatch({
        type: 'makeNavigationMove',
        payload: {
          currentMessageId: currentMessageId,
          currentMessage: currentMessage,
          currentPage: currentPage,
          routerHistory: updatedHistory,
        },
      });
      setDataToSessionStorage(KEYS.routerHistory, updatedHistory);
    }
  };

  const goHome = () => {
    const routerHistory = appState.routerHistory as string[];
    const widgetData = appState.widgetData;
    const rootMessageId = widgetData?.config.firstMessageId as string;
    const updatedHistory = routerHistory.slice(0, 1);
    const currentMessage = widgetData?.config.script[rootMessageId];
    const currentPage = isSinglePage(currentMessage?.pages) ? currentMessage?.pages[0] : undefined;

    if (appState.isResponsesGroupHide) {
      appActionDispatch({
        type: 'toggleResponsesGroupVisibility',
        payload: {
          isResponsesGroupHide: false,
        },
      });
      setDataToSessionStorage(KEYS.isResponsesGroupHide, false);
    }

    appActionDispatch({
      type: 'makeNavigationMove',
      payload: {
        currentMessageId: rootMessageId,
        currentMessage: currentMessage,
        currentPage: currentPage,

        routerHistory: updatedHistory,
      },
    });
    setDataToSessionStorage(KEYS.routerHistory, updatedHistory);
  };

  const setCurrentResponseGroupInfo = (index: number) => {
    appActionDispatch({
      type: 'setCurrentResponseGroupInfo',
      currentResponseGroupInfo: {
        index,
      },
    });
  };

  const makeWidgetInit = () => {
    if (!isInitialQueryParamsExist()) {
      // Save query params of the url, when user comes to page very first time.
      setInitialQueryParams(window.location.search);
    }
    fetchWidgetData({
      onSuccess: ([config, { widgetParameters, css }]) => {
        const widgetConfig = config;

        const serverSplitTestMode = widgetParameters.splitTestMode;
        const storedSplitTestMode = getDataFromSessionStorage(KEYS.splitTestMode);
        if (serverSplitTestMode !== storedSplitTestMode) {
          const splitTestGroup = getRandomTestVariant(widgetParameters.splitTestMode);
          setDataToSessionStorage(KEYS.splitTestGroup, splitTestGroup);
          setDataToSessionStorage(KEYS.splitTestMode, widgetParameters.splitTestMode);
        }

        if (widgetParameters.splitTestMode && widgetParameters.splitTestMode !== 'no-split-test') {
          const splitTestGroup: SplitTestGroup = getDataFromSessionStorage(KEYS.splitTestGroup);
          widgetConfig.script = mapValues(widgetConfig.script, (messageItem) => {
            return {
              ...messageItem,
              pages: messageItem.pages.filter(
                (page) => page.splitTestGroup === 'none' || page.splitTestGroup === splitTestGroup,
              ),
            };
          });
        }

        const routerHistory = getDataFromSessionStorage(KEYS.routerHistory);
        const widgetWasOpen = getDataFromSessionStorage(KEYS.widgetIsOpen);
        const widgetIsOpen =
          widgetWasOpen !== null ? widgetWasOpen : !isMobileScreen() && widgetParameters.isAutoopenEnabled;
        const lastMessageIdOfRouterHistory = routerHistory !== null ? last(routerHistory) : widgetConfig.firstMessageId;
        const currentMessageId = lastMessageIdOfRouterHistory
          ? lastMessageIdOfRouterHistory
          : widgetConfig.firstMessageId;
        const currentMessage = widgetConfig.script[currentMessageId];
        const currentPage = isSinglePage(currentMessage?.pages) ? currentMessage?.pages[0] : undefined;
        const personalDataCaptureParameters = widgetConfig.personalDataCaptureParameters || {
          isPostalCodeFieldEnabled: false,
          isAgeFieldEnabled: false,
          isGenderFieldEnabled: false,
        };
        const callbackRequestParameters = widgetConfig.callbackRequestParameters || {
          isEmailFieldEnabled: false,
        };

        setConsentSolution(widgetParameters.consentSolution, widgetParameters.consentSolutionCategory);

        const { isMixpanelAnalyticsEnabled, isGa4AnalyticsEnabled, ga4MeasurementId } = widgetParameters;

        initializeAnalytics({ isMixpanelAnalyticsEnabled, isGa4AnalyticsEnabled, ga4MeasurementId });
        if (widgetParameters.tenantGroupExternalId) {
          analytics.registerTenantGroupId({ tenantGroupId: widgetParameters.tenantGroupExternalId });
        }

        if (!appState.lottieReact && checkIfLottieAssetsExist(widgetConfig.script)) {
          importLottieReact();
        }

        if (widgetIsOpen && isMobileScreen()) {
          blockBodyScroll();
        }

        setDataToSessionStorage(KEYS.isResponsesGroupHide, false);

        appActionDispatch({
          type: 'setWidgetInitialData',
          payload: {
            widgetData: {
              config: { ...widgetConfig, personalDataCaptureParameters, callbackRequestParameters },
              parameters: { ...appState.widgetData.parameters, ...widgetParameters }, // Set default parameters if some parameters from the server don't exist.
              css: css ?? appState.widgetData.css,
            },
            widgetIsOpen: widgetIsOpen,
            currentMessageId: currentMessageId,
            currentMessage: currentMessage,
            currentPage: currentPage,
            routerHistory: routerHistory,
            isResponsesGroupHide: false,
          },
        });
      },
    });

    fetchAssets({
      onSuccess: (assets) => {
        appActionDispatch({
          type: 'setAssets',
          assets,
        });
      },
    });
  };

  const makeWidgetReset = () => {
    appActionDispatch({
      type: 'makeWidgetReset',
    });
    removeItemFromSessionStorage(KEYS.routerHistory);
    setDataToSessionStorage(KEYS.isResponsesGroupHide, false);
  };

  const registerFirstClick = () => {
    appActionDispatch({
      type: 'registerFirstClick',
    });
  };

  const setResponsesGroupHidden = (flag) => {
    appActionDispatch({
      type: 'toggleResponsesGroupVisibility',
      payload: { isResponsesGroupHide: flag },
    });
    setDataToSessionStorage(KEYS.isResponsesGroupHide, flag);
  };

  const selectPage = (pageId) => {
    const messageId = appState.currentMessageId as string;
    const currentMessage = appState.widgetData?.config.script[messageId];
    const currentPage = currentMessage?.pages.find((p) => p.id === pageId);

    appActionDispatch({
      type: 'selectPage',
      payload: { currentPage: currentPage },
    });
  };

  const changeHeight = (element: keyof DimensionsTypes, height: number) => {
    if (isMobileScreen()) {
      return;
    } else {
      appActionDispatch({
        type: 'changeElementHeight',
        payload: { propName: element, height },
      });

      appActionDispatch({ type: 'calculateWidgetHeight' });
    }
  };

  const contextValue = {
    appState,
    setWidgetOpenedStatus,
    makeWidgetInit,
    makeWidgetReset,
    goForward,
    goBack,
    goHome,
    setCurrentResponseGroupInfo,
    setResponsesGroupHidden,
    selectPage,
    registerFirstClick,
    importLottieReact,

    changeHeight,
  };

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
}

const useDataContext = () => {
  const context = React.useContext(DataContext);

  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataContextProvider');
  }

  return context as DataContextType;
};

export { DataContextProvider, useDataContext };
