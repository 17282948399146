import React from 'react';

import SlideText from './SlideText/SlideText';
import SlideImage from './SlideImage/SlideImage';

export default function Slide({ data: { url, caption, isImageLoading } }) {
  return (
    <div className="rcw-page-slide">
      <SlideImage src={url} alt={caption} isImageLoading={isImageLoading} />
      <SlideText caption={caption} />
    </div>
  );
}
