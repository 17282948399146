export const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000000, // should be bigger than zIndex of the widget
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.5)',
  },
  content: {
    position: 'null', // to override default styles
    top: 'null',
    left: 'null',
    right: 'null',
    bottom: 'null',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '32px',
    padding: '0',
    maxWidth: '84%',
    maxHeight: '90%',
    background: 'transparent',
    border: '0px',
    overflow: 'visible',
  },
};

export const contentWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  flex: '0 0 auto',
  margin: '0',
  padding: '0',
} as React.CSSProperties;

export const closeButton = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  right: '-25px',
  cursor: 'pointer',
  padding: 0,
  backgroundColor: 'transparent',
  color: '#7e7e7e',
  border: 'none',
} as React.CSSProperties;

export const crossIconStyles = {
  height: '16px',
  width: '16px',
};
