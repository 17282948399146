import React, { useEffect, useState, createContext } from 'react';
import { SliderItem, SliderContextProps } from './types';
import analytics from 'src/utils/analytics/analytics';

const initialSliderContext = {
  initSlider: () => {},
  clearSliderData: () => {},
  handleTouchStart: () => {},
  handleTouchMove: () => {},
  goToSlide: () => {},
  changeSlide: () => {},
  slidesCount: 1,
  slideNumber: 0,
  items: [],
};

const SliderContext = createContext<SliderContextProps>(initialSliderContext);

function SliderContextProvider({ children }) {
  const [items, setItems] = useState<SliderItem[]>([]);
  const [slideNumber, setSlideNumber] = useState<number>(0);
  const [touchPosition, setTouchPosition] = useState(null);

  const preloadImages = () => {
    const prevItemIndex = slideNumber - 1 < 0 ? items.length - 1 : slideNumber - 1;
    const nextItemIndex = (slideNumber + 1) % items.length;

    new Image().src = items[slideNumber].url;
    new Image().src = items[prevItemIndex].url;
    new Image().src = items[nextItemIndex].url;
  };

  useEffect(() => {
    if (items.length) {
      preloadImages();
    }
  }, [slideNumber, items]);

  useEffect(() => {
    if (items.length) {
      analytics.trackSlideViewed({
        slideId: items[slideNumber].id,
        slideImageUrl: items[slideNumber].url,
        slideNumber: slideNumber + 1,
      });
    }
  }, [slideNumber, items]);

  const initSlider = (data: SliderItem[]) => {
    setItems(data);
  };

  const clearSliderData = () => {
    setItems([]);
    setSlideNumber(0);
  };

  const changeSlide = (direction = 1) => {
    let slide = 0;

    if (slideNumber + direction < 0) {
      slide = items.length - 1;
    } else {
      slide = (slideNumber + direction) % items.length;
    }

    setSlideNumber(slide);
  };

  const goToSlide = (number: number) => {
    setSlideNumber(number % items.length);
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;

    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    if (touchPosition === null) {
      return;
    }

    const currentPosition = e.touches[0].clientX;
    const direction = touchPosition - currentPosition;

    if (direction > 10) {
      changeSlide(1);
    }

    if (direction < -10) {
      changeSlide(-1);
    }

    setTouchPosition(null);
  };

  const contextValue: SliderContextProps = {
    initSlider,
    clearSliderData,
    handleTouchStart,
    handleTouchMove,
    goToSlide,
    changeSlide,
    slidesCount: items.length,
    slideNumber,
    items,
  };

  return <SliderContext.Provider value={contextValue}>{children}</SliderContext.Provider>;
}

const useSliderContext = () => {
  const context = React.useContext(SliderContext);

  if (context === undefined) {
    throw new Error('useSliderContext must be used within a SliderContextProvider');
  }

  return context;
};

export { SliderContextProvider, useSliderContext };
