import analytics from 'src/utils/analytics/analytics';
import { KEYS, setDataToSessionStorage } from 'src/utils/persistentStorage';
import { CapturedPersonalData, CallbackRequest, DialogConfig, WidgetParameters } from './types';
import integrationId from 'src/utils/integrationId';

const URLS = {
  // Widget content and parameters files
  contentFile: `${process.env.REACT_APP_BASE_CONFIG_URL}/${integrationId}/chatbot/content.json`,
  widgetParametersFile: `${process.env.REACT_APP_BASE_CONFIG_URL}/${integrationId}/chatbot/widget-parameters.json`,
  assetsFile: `${process.env.REACT_APP_BASE_CONFIG_URL}/${integrationId}/chatbot/assets.json`,

  // Integration API
  capturePersonalData: `${process.env.REACT_APP_BASE_API_URL}/pdc/${integrationId}/capture-personal-data/`,
  callbackRequest: `${process.env.REACT_APP_BASE_API_URL}/cr/${integrationId}/capture-callback-request/`,
  chatbotOpeningSignal: `${process.env.REACT_APP_BASE_API_URL}/chatbot/${integrationId}/wet/`,
};

const headers = new Headers();
headers.append('cache-control', 'no-cache');

const fetchContent = {
  method: 'GET',
  headers,
};

export function fetchDataWithoutParams(url: string, noCache?: boolean) {
  return fetch(url, noCache ? undefined : fetchContent).then((r) => r.json());
}

export function fetchWidgetData({
  onSuccess,
  onError,
}: {
  onSuccess: (data: [DialogConfig, { widgetParameters: WidgetParameters; css: string }]) => void;
  onError?: (err: object | string) => void;
}) {
  Promise.all([fetchDataWithoutParams(URLS.contentFile), fetchDataWithoutParams(URLS.widgetParametersFile)])
    .then(onSuccess)
    .catch(onError);
}

export function sendChatbotOpeningSignal({ onError }: { onError: (err: object | string) => void }) {
  analytics.trackChatbotOpened();
  fetch(URLS.chatbotOpeningSignal, {
    method: 'POST',
  })
    .then(() => setDataToSessionStorage(KEYS.isChatbotOpeningSignalSent, true))
    .catch(onError);
}

export function sendCapturedPersonalData({
  body,
  onSuccess,
  onError,
}: {
  body: CapturedPersonalData;
  onSuccess: () => void;
  onError: (err: object | string) => void;
}) {
  fetch(URLS.capturePersonalData, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        return response;
      }
      if (response.status === 403) {
        throw new Error('The PDC feature is disabled');
      }
      if (response.status === 404) {
        throw new Error('Page not found');
      }
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      return Promise.reject(response);
    })
    .then(onSuccess)
    .catch(onError);
}

export function sendCallbackRequest({
  body,
  onSuccess,
  onError,
}: {
  body: CallbackRequest;
  onSuccess: () => void;
  onError: (err: object | string) => void;
}) {
  fetch(URLS.callbackRequest, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        return response;
      }
      if (response.status === 403) {
        throw new Error('The callback requests feature is disabled');
      }
      if (response.status === 404) {
        throw new Error('Page not found');
      }
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      return Promise.reject(response);
    })
    .then(onSuccess)
    .catch(onError);
}

export function fetchAssets({ onSuccess }: { onSuccess: (assets: Object) => void }) {
  fetchDataWithoutParams(URLS.assetsFile).then(onSuccess);
}
