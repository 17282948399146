import { useState, ChangeEvent, FormEvent } from 'react';
import omit from 'lodash/omit';

interface Validation {
  required?: {
    value: boolean;
    message: string;
  };
  pattern?: {
    value: string;
    message: string;
  };
  custom?: {
    isValid: (value: string) => boolean;
    message: string;
  };
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

type Validations<T extends {}> = Partial<Record<keyof T, Validation>>;

const useForm = <T extends Record<keyof T, any> = {}>(options?: {
  validations?: Validations<T>;
  initialValues?: Partial<T>;
  onSubmit?: () => void;
}) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});
  const [touchedData, setTouched] = useState<T>({} as T);
  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  // Needs to extend unknown so we can add a generic to an arrow function
  const handleChange =
    <S extends unknown>(key: keyof T, sanitizeFn?: (value: string) => S) =>
    (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
      const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
      setData({
        ...data,
        [key]: value,
      });

      const currentlyTouchedData = { ...touchedData, [key]: true };
      setTouched(currentlyTouchedData);

      if (submittedOnce) {
        if (currentlyTouchedData[key]) {
          const validations = options?.validations;
          if (validations) {
            let valid = true;
            const newErrors: ErrorRecord<T> = {};

            // TODO: Figure out how to move the repetitive validation logic into a separate block.
            const validation = validations[key];
            if (validation?.required?.value && !value) {
              valid = false;
              newErrors[key] = validation?.required?.message;
            }

            const pattern = validation?.pattern;
            if (pattern?.value && !RegExp(pattern.value).test(value as string)) {
              valid = false;
              newErrors[key] = pattern.message;
            }

            const custom = validation?.custom;
            if (custom?.isValid && !custom.isValid(value as string)) {
              valid = false;
              newErrors[key] = custom.message;
            }

            if (!valid) {
              setErrors({ ...errors, ...newErrors });
              return;
            } else {
              // @ts-ignore
              setErrors(omit(errors, [key])); // TODO: ts(2345)
              return;
            }
          }
        }
      }
    };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validations = options?.validations;
    if (validations) {
      let valid = true;
      const newErrors: ErrorRecord<T> = {};
      for (const key in validations) {
        const value = data[key];
        const validation = validations[key];
        if (validation?.required?.value && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }
      }

      if (!valid) {
        setErrors(newErrors);
        setSubmittedOnce(true);
        return;
      }
    }

    setErrors({});

    if (options?.onSubmit) {
      options.onSubmit();
    }
  };

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
  };
};

export default useForm;
