import React, { createContext, useState, useCallback, useEffect } from 'react';

import { LayoutStateContextType } from './types';

/* - - - - - - - - - - - - - - - - - - - - - - */

const LayoutStateContext = createContext<Partial<LayoutStateContextType>>({});

function LayoutStateContextProvider({ children }: { children: React.ReactNode }) {
  const [wistiaVideoModalIsVisible, setWistiaVideoModalIsVisible] = useState<boolean>(false);
  const [child, setChild] = useState();
  const [isLoading, setLoading] = useState<boolean>(true);

  const toggleModalIsVisible = useCallback(status => setWistiaVideoModalIsVisible(status), []);
  const setModalChild = useCallback(child => setChild(child), []);

  useEffect(() => {
    if (!wistiaVideoModalIsVisible) {
      setLoading(true);
    }
  }, [wistiaVideoModalIsVisible]);

  const contextValue = {
    wistiaVideoModalIsVisible,
    toggleModalIsVisible,
    child,
    setModalChild,
    isLoading,
    setLoading,
  };

  return <LayoutStateContext.Provider value={contextValue}>{children}</LayoutStateContext.Provider>;
}

const useLayoutStateContext = () => {
  const context = React.useContext(LayoutStateContext);

  if (context === undefined) {
    throw new Error('useLayoutStateContext must be used within a LayoutStateContextProvider');
  }

  return context as LayoutStateContextType;
};

export { LayoutStateContextProvider, useLayoutStateContext };
