import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import SVG from 'react-inlinesvg';

import { useDataContext } from 'src/context/data';

import { FormPage } from 'src/api/types';
import { KEYS, getDataFromSessionStorage } from 'src/utils/persistentStorage';

import LottieAnimation from 'src/components/LottieAnimation/LottieAnimation';
import InformationMessage from 'src/components/InformationMessage/InformationMessage.tsx';
import CapturePersonalDataForm from 'src/components/PersonalDataCaptureForm/CapturePersonalDataForm';
import TextBuilder from '../TextBuilder/TextBuilder';

type PageFormProps = {
  page: FormPage;
};

function PageForm({ page }: PageFormProps) {
  const {
    appState: {
      assets,
      widgetData: {
        parameters: { robotBackgroundColor, robotTextColor, robotBorderColor },
      },
      currentMessageId,
    },
  } = useDataContext();

  const isFormSubmitted = Boolean(getDataFromSessionStorage(KEYS.isPDCFormSubmitted));

  const [isCapturePersonalDataFormVisible, setIsCapturePersonalDataFormVisible] = useState(!isFormSubmitted);
  const [isCapturePersonalDataFormWasSentMessage, setIsCapturePersonalDataFormWasSentMessage] = useState(false);
  useState(false);

  useEffect(() => {
    // Show message about submitted form only if user returns on that page again.
    setIsCapturePersonalDataFormWasSentMessage(isFormSubmitted);
  }, []);

  const assetContent = page.asset && assets[page.asset.id];

  const text = page.contentBody;

  const isFull = Boolean(page.asset || text?.length >= 100);

  const footnoteText = page.footnote;

  return (
    <div
      id={page.slug}
      className={`rcw-page-body ${isFull ? 'rcw-page-body_full' : ''}`}
      key={'page-body-' + currentMessageId}
      style={
        isFull ? {} : { backgroundColor: robotBackgroundColor, borderColor: robotBorderColor, color: robotTextColor }
      }
    >
      {page.asset && page.asset.type === 'animation-lottie' && (
        <div className="rcw-lottie-container">
          <LottieAnimation className="rcw-lottie-animation" data={assetContent} />
        </div>
      )}

      {page.asset && page.asset.type === 'image-svg' && (
        <div className="rcw-svg-container">
          <SVG className="rcw-image-svg" src={assetContent} />
        </div>
      )}

      <TextBuilder text={text} />

      {!isEmpty(footnoteText) && <p className="rcw-page-text-footnote">{footnoteText}</p>}

      {isCapturePersonalDataFormWasSentMessage && (
        <InformationMessage>
          You have already filled out this form. If you have not already received an email from us check your Spam
          folder.
        </InformationMessage>
      )}

      {isCapturePersonalDataFormVisible && (
        <CapturePersonalDataForm onDone={() => setIsCapturePersonalDataFormVisible(false)} />
      )}
    </div>
  );
}

export default PageForm;
