import React from 'react';

import PageArticle from './PageArticle';
import PageVideo from './PageVideo';
import PageForm from './PageForm';
import PageCarousel from './PageCarousel';
import { AnyPage, ArticlePage } from 'src/api/types';
import PageCallbackRequest from './PageCallbackRequest';
import PageConfirmation from './PageConfirmation';

type PageProps = {
  page: (AnyPage & Partial<Pick<ArticlePage, 'asset'>>) | undefined;
  callback?: () => void;
};

function Page({ page, callback }: PageProps) {
  if (!page) {
    return null;
  }

  switch (page.pageType) {
    case 'article':
      return <PageArticle page={page} />;

    case 'video':
      return <PageVideo page={page} />;

    case 'form':
      return <PageForm page={page} />;

    case 'callback-request':
      const callCallback = () => {
        if (callback) {
          callback();
        }
      };
      return <PageCallbackRequest page={page} onFormSubmit={callCallback} />;

    case 'confirmation':
      return <PageConfirmation page={page} />;

    case 'carousel':
      return <PageCarousel page={page} />;

    default:
      return null;
  }
}

export default Page;
