import React, { useRef, useEffect, useCallback, CSSProperties } from 'react';

import { useDataContext } from 'src/context/data';

import Testimonials from 'src/Widget/Conversation/Testimonials/Testimonials';

import { renderCloseIcon } from 'src/utils/iconRenderers';
import useResizeObserver from 'src/utils/hooks/useResizeObserver';

import * as dimensions from 'src/utils/styles/dimensions.module.scss';

/* - - - - - - - - - - - - - - - - - - - - - */

const COLLAPSED_TESTIMONIAL_WITH_LOGO_WIDTH = window.innerWidth < dimensions.mobileBreakpoint ? 140 : 160;
const COLLAPSED_TESTIMONIAL_WITHOUT_LOGO_WIDTH = window.innerWidth < dimensions.mobileBreakpoint ? 190 : 225;

/* - - - - - - - - - - - - - - - - - - - - - */

function Launcher() {
  const {
    appState: {
      widgetIsOpen,
      widgetData: { parameters, config },
    },
    setWidgetOpenedStatus,
    makeWidgetReset,
    changeHeight,
  } = useDataContext();

  const launcherRef = useRef<HTMLDivElement>(null);
  useResizeObserver(
    launcherRef,
    useCallback((entry) => {
      changeHeight('launcherHeight', Math.ceil(entry.contentRect.height));
    }, []),
  );

  useEffect(() => {
    if (!widgetIsOpen) {
      makeWidgetReset();
    }
  }, [widgetIsOpen]);

  const renderFullWidthContent = () => {
    return (
      <div className={`rcw-launcher-content-holder ${widgetIsOpen ? 'minimized' : 'full-width'}`}>
        {parameters?.collapsedBrandUrl && (
          <div
            className={`rcw-launcher-logo-holder ${widgetIsOpen ? 'minimized' : 'full-width'}`}
            data-cy="launcher-logo-holder"
          >
            <img src={parameters?.collapsedBrandUrl} alt="Logo" />
          </div>
        )}
        <Testimonials
          maxCollapsedWidth={
            parameters?.collapsedBrandUrl
              ? COLLAPSED_TESTIMONIAL_WITH_LOGO_WIDTH
              : COLLAPSED_TESTIMONIAL_WITHOUT_LOGO_WIDTH
          }
          parameters={parameters}
          dialogConfig={config}
          collapsed
        />
      </div>
    );
  };

  const renderRoundWidthContent = () => {
    if (widgetIsOpen) return renderCloseIcon(parameters.collapsedButtonTextColor);

    return (
      <div className={`rcw-launcher-circle-holder ${widgetIsOpen ? 'minimized' : 'full-width'}`}>
        <div className="rcw-launcher-circle" />
      </div>
    );
  };

  return (
    <>
      <div ref={launcherRef} className="rcw-launcher-button-wrapper">
        <div className={`rcw-launcher-button-holder ${widgetIsOpen ? 'minimized' : ''}`}>
          <button
            className={`rcw-launcher-animated-button ${widgetIsOpen ? 'minimized' : 'full-width'}`}
            style={
              {
                '--collapsed-button-background-color': parameters.collapsedButtonBackgroundColor,
                '--collapsed-button-text-color': parameters.collapsedButtonTextColor,
              } as CSSProperties
            }
            onClick={() => setWidgetOpenedStatus(!widgetIsOpen)}
            data-cy="launcher-btn"
          >
            {renderRoundWidthContent()}
            {renderFullWidthContent()}
          </button>
        </div>
      </div>
    </>
  );
}

export default Launcher;
