import { State, ActionType } from './types';
import { sendChatbotOpeningSignal } from 'src/api';
import { getDataFromSessionStorage, KEYS } from 'src/utils/persistentStorage';
import { isSinglePage } from 'src/utils/common';
import analytics from 'src/utils/analytics/analytics';
import { TrackPageViewedData } from 'src/utils/analytics/types';
import { shouldWidgetCollectPersonalData } from 'src/utils/cookieBanners';

const routerHistory = getDataFromSessionStorage(KEYS.routerHistory);

export const initialState: State = {
  widgetData: {
    config: {
      firstMessageId: '',
      script: {},
      credibilityBuilders: [],
      testimonials: [],
      phoneNumberParameters: {
        phoneNumber: '',
      },
      htmlDocuments: {},
      personalDataCaptureParameters: {
        isPostalCodeFieldEnabled: false,
        isAgeFieldEnabled: false,
        isGenderFieldEnabled: false,
      },
      callbackRequestParameters: {
        isEmailFieldEnabled: false,
      },
    },
    parameters: {
      titleFontColor: '',
      titleBackgroundColor: '',
      robotBackgroundColor: '',
      robotTextColor: '',
      robotBorderColor: '',
      userBackgroundColor: '',
      userTextColor: '',
      collapsedButtonTextColor: '',
      chatOptionButtonBackgroundColor: '',
      collapsedSpeechBalloonColor: '',
      chatOptionButtonTextColor: '',
      collapsedButtonBackgroundColor: '',
      customerResponsePageSize: 4, // Number of response buttons per response group.
      linkColor: '',
      phoneIconColor: '',
      fontSize: 14,
      title: '',
      subTitle: '',
      collapsedBrandUrl: null,
      chatbotWidth: 450,
      chatbotHeight: 600,
      isCompactLauncherEnabled: false,
      isUserShadowEnabled: true,
      isUserBorderEnabled: true,
      isAutoopenEnabled: true,
      isTestimonialsAndCredibilityBuildersBarEnabled: false,
      isPhoneNumberEnabled: false,
      isShowSiblingPageButtonsEnabled: false,
      isMixpanelAnalyticsEnabled: false,
      isGa4AnalyticsEnabled: false,
      ga4MeasurementId: '',
      consentSolution: 'no-consent-solution',
      consentSolutionCategory: '',
      customerResponseScoringRules: {},
      splitTestMode: 'no-split-test',
      tenantGroupExternalId: null,
    },
    css: '',
  },
  widgetIsOpen: Boolean(getDataFromSessionStorage(KEYS.widgetIsOpen)),
  previousMessageId: undefined,
  currentMessageId: '',
  currentMessageTitle: '',
  currentMessage: {
    slug: '',
    label: '',
    pages: [],
    quickResponses: [],
  },
  currentPage: undefined,
  currentResponseGroupInfo: {
    index: 0,
  },
  isResponsesGroupHide: false,
  routerHistory: routerHistory !== null ? routerHistory : [],
  assets: {},
  isFirstClickRegistered: Boolean(getDataFromSessionStorage(KEYS.isChatbotOpeningSignalSent)),
  lottieReact: undefined,

  dimensions: {
    widgetHeight: 0,
    headerHeight: 0,
    testimonialsHeight: 50,
    messageHeight: 0,
    actionsHeight: 0,
    responsesHeight: 0,
    launcherHeight: 0,
  },
};

export function appContextReducer(state: State, action: ActionType) {
  switch (action.type) {
    case 'setWidgetInitialData': {
      return {
        ...state,
        widgetData: action.payload.widgetData,
        widgetIsOpen: action.payload.widgetIsOpen,
        currentMessageId: action.payload.currentMessageId,
        routerHistory: action.payload.routerHistory ? action.payload.routerHistory : [action.payload.currentMessageId],
        currentResponseGroupInfo: {
          index: 0,
        },
        currentMessage: action.payload.currentMessage,
        currentPage: action.payload.currentPage,
        isResponsesGroupHide: action.payload.isResponsesGroupHide,
      };
    }
    case 'makeWidgetReset': {
      const currentMessage = state.widgetData?.config.script[state.widgetData?.config.firstMessageId];

      return {
        ...state,
        currentMessageId: state.widgetData?.config.firstMessageId,
        currentMessage: currentMessage,
        currentPage: isSinglePage(currentMessage?.pages) ? currentMessage?.pages[0] : undefined,
        pages: currentMessage?.pages,
        routerHistory: [state.widgetData?.config.firstMessageId],
        currentResponseGroupInfo: {
          index: 0,
        },
        isResponsesGroupHide: false,
      };
    }
    case 'setAssets':
      return {
        ...state,
        assets: action.assets,
      };
    case 'setWidgetOpenedStatus':
      if (state.currentMessageId) {
        if (state.currentMessage) {
          analytics.registerMessageData({ messageId: state.currentMessageId });

          analytics.trackMessageViewed({
            previousMessageId: state.currentMessageId,
            messageLabel: state.currentMessage.label,
          });
        }

        if (state.currentPage) {
          const trackedData: TrackPageViewedData = {
            messageId: state.currentMessageId,
            previousMessageId: state.currentMessageId,
            pageId: state.currentPage.id,
            pageType: state.currentPage.pageType,
            pageCaption: state.currentPage.caption,
          };

          if ('asset' in state.currentPage && state.currentPage.asset) {
            trackedData.assetType = state.currentPage.asset.type;
          }

          analytics.trackPageViewed(trackedData);
        }
      }

      return {
        ...state,
        widgetIsOpen: action.statusFlag,
      };
    case 'makeNavigationMove': {
      if (state.currentMessageId && action.payload.currentMessage) {
        analytics.registerMessageData({ messageId: action.payload.currentMessageId });

        analytics.trackMessageViewed({
          previousMessageId: state.currentMessageId,
          messageLabel: action.payload.currentMessage.label,
        });
      }

      if (action.payload.currentPage) {
        const trackedData: TrackPageViewedData = {
          messageId: action.payload.currentMessageId,
          previousMessageId: state.currentMessageId,
          pageId: action.payload.currentPage.id,
          pageType: action.payload.currentPage.pageType,
          pageCaption: action.payload.currentPage.caption,
        };

        if ('asset' in action.payload.currentPage && action.payload.currentPage.asset) {
          trackedData.assetType = action.payload.currentPage.asset.type;
        }

        analytics.trackPageViewed(trackedData);
      }

      return {
        ...state,
        previousMessageId: state.currentMessageId,
        currentMessageId: action.payload.currentMessageId,
        currentMessage: action.payload.currentMessage,
        currentPage: action.payload.currentPage,
        routerHistory: action.payload.routerHistory,
        currentResponseGroupInfo: {
          index: 0,
        },
      };
    }
    case 'setCurrentResponseGroupInfo':
      return {
        ...state,
        currentResponseGroupInfo: action.currentResponseGroupInfo,
      };

    case 'toggleResponsesGroupVisibility':
      if (action.payload.isResponsesGroupHide) {
        return {
          ...state,
          isResponsesGroupHide: action.payload.isResponsesGroupHide,
        };
      }
      return {
        ...state,
        isResponsesGroupHide: action.payload.isResponsesGroupHide,
        currentPage: undefined,
      };

    case 'selectPage': {
      if (state.currentMessageId) {
        const trackedData: TrackPageViewedData = {
          pageId: action.payload.currentPage.id,
          pageType: action.payload.currentPage.pageType,
          pageCaption: action.payload.currentPage.caption,
          messageId: state.currentMessageId,
          previousMessageId: state.previousMessageId,
        };

        if ('asset' in action.payload.currentPage && action.payload.currentPage.asset) {
          trackedData.assetType = action.payload.currentPage.asset.type;
        }

        analytics.trackPageViewed(trackedData);

        return {
          ...state,
          currentPage: action.payload.currentPage,
        };
      }
      return state;
    }

    case 'registerFirstClick': {
      const hasWidgetEverOpened = getDataFromSessionStorage(KEYS.widgetIsOpen) == null;
      const isFirstClickRegistered = getDataFromSessionStorage(KEYS.isChatbotOpeningSignalSent);

      if (
        state.widgetData?.parameters.isAutoopenEnabled &&
        hasWidgetEverOpened &&
        !isFirstClickRegistered &&
        state.routerHistory.length === 1
      ) {
        if (shouldWidgetCollectPersonalData()) {
          sendChatbotOpeningSignal({
            onError: (err) => console.error(err),
          });
        }

        if (state.currentMessageId) {
          if (state.currentMessage) {
            analytics.registerMessageData({ messageId: state.currentMessageId });

            analytics.trackMessageViewed({
              previousMessageId: state.currentMessageId,
              messageLabel: state.currentMessage.label,
            });
          }

          if (state.currentPage) {
            const trackedData: TrackPageViewedData = {
              messageId: state.currentMessageId,
              previousMessageId: state.currentMessageId,
              pageId: state.currentPage.id,
              pageType: state.currentPage.pageType,
              pageCaption: state.currentPage.caption,
            };

            if ('asset' in state.currentPage && state.currentPage.asset) {
              trackedData.assetType = state.currentPage.asset.type;
            }

            analytics.trackPageViewed(trackedData);
          }
        }

        return { ...state, isFirstClickRegistered: true };
      }
      return state;
    }

    case 'setLottieReact':
      return {
        ...state,
        lottieReact: action.payload.lottieReact,
      };

    case 'changeElementHeight':
      return {
        ...state,
        dimensions: { ...state.dimensions, [action.payload.propName]: action.payload.height },
      };

    case 'calculateWidgetHeight':
      const { headerHeight, testimonialsHeight, messageHeight, actionsHeight, responsesHeight, launcherHeight } =
        state.dimensions;
      let widgetHeight = headerHeight + messageHeight + actionsHeight + responsesHeight + launcherHeight;

      if (
        state.widgetData?.parameters.isTestimonialsAndCredibilityBuildersBarEnabled &&
        (state.widgetData.config.testimonials.length > 0 || state.widgetData.config.credibilityBuilders.length > 0)
      ) {
        widgetHeight = widgetHeight + testimonialsHeight;
      }
      return {
        ...state,
        dimensions: { ...state.dimensions, widgetHeight },
      };
  }
}
